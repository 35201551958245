import axios from 'axios';

export default {
  namespaced: true,

  state: {
    surveys: [],
    forRed:[]
  },

  getters: {
    surveys: state => state.surveys,
    getForRed: state => state.forRed,
  },

  actions: {
    setSurveys({ commit, state }, data) {
      state.surveys = data;
    },
    unsetSurvey({ commit, state }, survey) {
      const index = state.surveys.indexOf(survey);
      state.surveys.splice(index, 1);
    },

    setForRed({state},questions){

      state.forRed=questions

    }
  },
};
