<template>
  <div>
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="student-location-modal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="xl">
      <!-- Modal content -->
      <div class="row text-white content text-left">
        <p>Dear international students,</p>
        <p>&nbsp;</p>
        <p>As you are aware, the final exams will be conducted on campus of the University of Georgia.&nbsp; As some of you have been inquiring about this matter, we would like to explain the situation in a way to help you understand it better.&nbsp; &nbsp; As you are aware, according to the Georgian Law, universities in Georgia are not allowed to conduct online exams for those students who are not present in Georgia.&nbsp; Due to the fact that we have tried, numerous times to identify students who are in Georgia and abroad, this process has not been successful because students have not been providing us with the valid information or have not contacted us at all.&nbsp; Because online exams for those who are abroad would mean that the exams are not valid - we are obligated, because of the large number of international students and due to the problem of obtaining valid information about their whereabouts - to conduct exams on campus in order to avoid the problem of invalid and unformal status of exams for those students who are in fact abroad.&nbsp;</p>
        <p>&nbsp;</p>
        <p>As you are aware, all students, including the citizens of Georgia, will have exams on campus.&nbsp; The good news is that we have prolonged the examination process to ensure small number of students in a group and have been preparing our exam infrastructure to accommodate fully all the necessary health safety regulations.&nbsp; You will have distance, individual safe space and all necessary tools to keep safe.&nbsp; Because the covid regulations have slowly been lifted in the country - public transport and restaurants have been allowed to function - Universities have been given green light to conduct the exams as they see fit.&nbsp; It is safe to say that the examination infrastructure at the University of Georgia will be one of the safest places to be.&nbsp; &nbsp;We would like to remind you that hundreds of students who have identified serious health conditions - have been granted the opportunity to have online exams.</p>
        <p>&nbsp;</p>
        <p>With this, we would like to take into consideration your concerns about your insurance, therefore, if you have ACTIVE STUDENT STATUS,&nbsp; we would kindly like to ask you to register here until June 25th for us to be able to start the process of your insurance.&nbsp;</p>
        <p>&nbsp;</p>
        <p>Wishing you good health and success at your exams!</p>
        <p>&nbsp;</p>
        <p>UG</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <div class="mt-2 text-center">
        <button class="btn btn-light-bold mr-4" @click="handleSubmit(1)">I am in Georgia</button>
        <button class="btn btn-light-bold" @click="handleSubmit(0)">I am not in Georgia</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'student-location-modal',
  data() {
    return {
      isInGeorgia: 0,
    }
  },

  mounted() {
    this.showModal();
  },

  methods: {
    showModal() {
      this.$bvModal.show('student-location-modal');
    },
    close() {
      this.$bvModal.hide('student-location-modal');
    },

    handleSubmit(isInGeorgia) {
      axios.post('/api/student-location', {
        is_in_georgia: isInGeorgia
      })
      .then(response => {
        this.close();
      })
    }
  }
};
</script>
<style scoped>
  .content {
    font-size: 1.6rem;
  }
</style>
