<template>
  <div>
    <b-modal
      @shown="onShown()"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="seminar-groups-modal"
      size="xl">
        <!-- Modal content-->
        <div class="row ">
          <div class="col mb-5 pb-5 ">
            <h4 class="mb-5">{{ $t('registration.choose-seminar-group') }}</h4>
            <groups-table from="seminar" @choose="handleGroupClick" :loading="loading" :subject="subject" :groups="groups"></groups-table>
          </div>
        </div>
        <div class="row">
          <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
            <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('registration.close') }}</button>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SkList from '../skeletons/sk-list';
  import GroupsTable from './groups-table';

  export default {
    name: 'seminar-groups-modal',
    components: { GroupsTable, SkList },

    props: ['subject', 'group', 'already-registered'],

    data() {
      return {
        loading: true,
        groups: [],
      }
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('seminar-groups-modal');
        this.$emit('closed');
        this.groups = [];
        location.reload()
      },

      onShown() {
        this.$store.dispatch('registration/getSubjectSeminarGroups', {
          scheduleID: this.group.scheduleID
        })
          .then(response => {
            this.groups = response.data.data;
            this.loading = false;
          })
          .catch(error => {
            this.hideModal();
            this.helpers.notifyErrorMessage(error);
          })
      },

      handleGroupClick(group) {
        if(parseInt(this.group.has_practice)) {
          this.$emit('open-practise-modal', group);
        } else {
          this.$swal({
            title: "",
            text: this.$t('registration.confirm-registration'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: this.$t('registration.minor-modal.ok'),
            cancelButtonText: this.$t('registration.minor-modal.cancel'),
            closeOnConfirm: false,
            closeOnCancel: false
          }).then((result) => {
            if(result && result.isConfirmed)
              this.$emit('register-seminar', group);
          });
        }
      },
    }
  };
</script>
