<template>
<div>
    <div class="row">
      <div class="col-12">
       <div class="mx-3">

123

       </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rating-question',

  props: ['question'],
};
</script>

<style scoped>

</style>
