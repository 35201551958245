<template>
   <div>
    <div class="row">
      <div class="col-12">

        <!-- example -->
        <div class="form-check">
          <input 
            class="form-check-input" 
            type="radio" 
            name="exampleRadios" 
            id="exampleRadios1" 
            value="option1" checked>
          <label class="form-check-label" for="exampleRadios1">
            კი
          </label>
        </div>

        <div class="form-check">
          <input 
            class="form-check-input" 
            type="radio" 
            name="exampleRadios" 
            id="exampleRadios2" 
            value="option2">
          <label class="form-check-label" for="exampleRadios2">
            არა
          </label>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'true-false-question',

  props: ['question'],
};
</script>

<style scoped>

</style>
