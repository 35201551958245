import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import navbar from './navbar';
import user from './user';
import semester from './semester';
import program from './program';
import conversation from './conversation';
import exam from './exam';
import popup_messages from './popup_messages';
import evaluation from './evaluation';
import registration from './registration';
import pre_registration from './pre-registration';
import language from './language';
import notifications from './notifications';
import survey from './survey';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth, navbar, notifications, user,
    semester, program, conversation, exam, survey,
    popup_messages, evaluation, registration, pre_registration, language
  },
});
