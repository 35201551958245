<template>
  <div>
    <!-- subject_groups -->
    <button class="btn btn-light-medium" v-b-modal.send-request-modal>{{ $t('registration.send-request.send-request') }}</button>

    <b-modal
      @shown="onShown()"
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="send-request-modal"
      size="xl"
      centered>
        <!-- Modal content-->
        <div class="row">
          <div class="col-12 col-md-8 offset-md-2 mb-5 pb-5">
            <h4 class="mb-5">{{ $t('registration.send-request.send-request') }}</h4>
            <div class="registration-group-table row" v-if="days && times">
              <b-form-group
                :label="$t('registration.send-request.choose_day')"
                label-for="day"
                class="f-p2 col-12 col-md-6">
                <multiselect
                  id="day"
                  v-model="form.day"
                  :options="days"
                  track-by="dayID"
                  :label="locale == 'ka' ? 'day' : 'dayeng'"
                  placeholder="Choose day.."
                ></multiselect>
              </b-form-group>
              <b-form-group
                :label="$t('registration.send-request.choose_time')"
                label-for="time"
                class="f-p2 col-12 col-md-6">
                <multiselect
                  id="time"
                  v-model="form.time"
                  :options="times"
                  track-by="lecture_startID"
                  label="lecture_start"
                  placeholder="Choose time.."
                ></multiselect>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
            <button type="button" class="btn btn-light-bold" @click="handleSendClick()">{{ $t('registration.send-request.send') }}</button>
            <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('registration.close') }}</button>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
  import ScheduleResource from '../../api/scheduleResource';
  import Multiselect from 'vue-multiselect';
  import { mapGetters } from 'vuex';

  const scheduleResource = new ScheduleResource();

  export default {
    name: 'send-request-modal',
    components: { Multiselect },
    props: ['subject'],
    data() {
      return {
        loading: true,
        days: [],
        times: [],
        form: {
          day: '',
          time: '',
        }
      }
    },

    computed: {
      ...mapGetters({
        locale: 'language/locale'
      })
    },

    methods: {
      onShown() {
        this.form = {
          day: '',
          time: '',
        };
        this.loadDateTimes();
      },

      handleSendClick() {
        this.$store.dispatch('registration/sendRequest', {
          subjectID: this.subject.subjectID,
          dayID: this.form.day ? this.form.day.dayID : '',
          timeID: this.form.time ? this.form.time.lecture_startID : '',
        })
        .then(() => {
          this.$swal({
            title: "",
            text: this.$t('registration.send-request.request-successfully-sent'),
            icon: "success",
            showConfirmButton: false,
            showCancelButton: false,
            confirmButtonText: false,
            cancelButtonText: false
          });
          this.hideModal();
        })
        .catch((error) => {
          this.helpers.notifyErrorMessage(error);
        });
      },

      loadDateTimes() {
        this.loading = true;
        scheduleResource.dateTimes()
          .then(response => {
            this.days = response.data.data.days;
            this.times = response.data.data.times;
          })
          .finally(() => {
            this.loading = false;
          });
      },

      hideModal() {
        this.$bvModal.hide('send-request-modal');
      },
    }
  };
</script>
<style scoped>
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
</style>
