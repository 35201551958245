<template>
  <div>
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="graduation-come-modal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="xl">
      <!-- Modal content -->
      <div class="row text-white content">

                    <div class="col-12">   {{$t('graduation.come')}}  </div>


                     <div class="col-12">   <input type="radio"  name="type" id="yes" class="radio" @click="chosenType('yes')"  />-  {{$t('graduation.yes')}} </div>  
                     <div class="col-12">  <input type="radio"  name="type" id="no" class="radio"  @click="chosenType('no')"  />-  {{$t('graduation.no')}} </div>
                     <div class="col-12">  <input type="radio"  name="type" id="notDecided" class="radio"  @click="chosenType('notDecided')"  />-  {{$t('graduation.notDecided')}} </div>    




      </div>
    </b-modal>

  </div>
</template>

<script>
 

export default {
            methods:{

                    chosenType(type){

                                if(confirm("ნამდვილად გსურთ არჩეული პასუხის შენახვა?/Are you sure you want to save the selected answer?")){

                                        this.$store.dispatch('auth/graduationComeAnswerSave',{answer:type}).then(response=>{

                                               window.location.href='/'


                                        })


                                }    


                    }


            }


   
};
</script>

<style scoped>
.radio{
 height: 20px;
  width: 20px;
    

}

</style>
 