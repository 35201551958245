<template>
  <div class="subjects-container">
    <p class="text-left">{{ $t('surveys.please-choose-subject') }}</p>
    <div
      :class="['subject', { active: activeSubject && activeSubject.subjectID === subject.subjectID }]"
      v-for="subject of subjects">
      <div @click="toggleSubject(subject)"
          class="subject-title">{{ helpers.getLocalizedField(subject, 'subject') }}</div>

      <div v-if="activeSubject && activeSubject.subjectID === subject.subjectID" class="questions">
        <div v-for="question of survey.questions">
          <survey-questions
            v-if="isQuestionForSubject(question)"
            @answered="handleAnswer"
            :answers="answers"
            :subject-id="activeSubject.subjectID"
            :question="question"></survey-questions>
        </div>

        <p v-if="lecturers.length > 0" class="mt-4 text-left">{{ $t('surveys.please-choose-lecturer') }}</p>
        <div :class="['lecturer', { active: activeLecturer && activeLecturer.personID === lecturer.personID }]"
             v-for="lecturer of lecturers">
          <div @click="toggleLecturer(lecturer)"
               class="subject-title">{{ helpers.getLocalizedField(lecturer, 'Lectors') }}</div>
          <div v-if="activeLecturer && activeLecturer.personID === lecturer.personID">
            <div v-for="question of survey.questions">
              <survey-questions
                v-if="!isQuestionForSubject(question)"
                @answered="handleAnswer"
                :answers="answers"
                :lecturer-id="activeLecturer.personID"
                :subject-id="activeSubject.subjectID"
                :question="question"></survey-questions>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center"><button @click="handleSave" class="btn btn-light-medium">{{ $t('surveys.submit') }}</button></div>
  </div>
</template>

<script>
import SurveyResource from '../../api/surveyResource';
import SurveyQuestions from './survey-questions';

const surveyResource = new SurveyResource();

export default {
  name: 'subject-lecturer-survey',
  components: { SurveyQuestions },
  props: ['survey'],

  data() {
    return {
      subjects: [],
      lecturers: [],
      activeSubject: null,
      activeLecturer: null,
      answers: [],
    }
  },

  created() {
    this.loadSubjects();
  },

  watch: {
    activeSubject(val) {
      if(val) this.loadLecturers();
    }
  },

  methods: {
    handleSave() {
      surveyResource.save(this.survey.surveyID, this.answers)
      .then(response => {
        this.helpers.notifySuccessMessage(this.$t('surveys.success'));
        this.$emit('submitted', this.survey);
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error);
      });
    },

    handleAnswer(answerData) {
      let alreadyAnswered = Object.assign([], this.answers)
        .find(o => o.questionId === answerData.questionId
          && o.subjectId === answerData.subjectId
          && o.lecturerId === answerData.lecturerId
          && o.type === answerData.type);

      if(alreadyAnswered) this.answers.splice(this.answers.indexOf(alreadyAnswered), 1);

      this.answers.push(answerData);
    },

    isQuestionForSubject(question) {
      return question.item.question_for === 'subject'
          || (question.item.questions && question.item.questions[0].question_for === 'subject')
    },

    toggleSubject(subject) {
      if(!this.activeSubject)
        this.activeSubject = subject;
      else
        this.activeSubject = null;
    },

    toggleLecturer(lecturer) {
      if(!this.activeLecturer)
        this.activeLecturer = lecturer;
      else
        this.activeLecturer = null;
    },

    loadSubjects() {
      surveyResource.subjects(this.survey.surveyID)
        .then(response => {
          this.subjects = response.data.data;
        });
    },

    loadLecturers() {
      surveyResource.lecturers(this.survey.surveyID, this.activeSubject.subjectID)
        .then(response => {
          this.lecturers = response.data.data;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.subject{
  background-color: rgba(255,255,255,.95);
  border-radius:30px;
  margin-bottom: 1.5rem;
  color: #557c84;
  padding: 1rem 1.95rem ;
  text-align: left;
  font-size: 1.75rem;
  line-height: 25px;
  box-shadow: 0 1px 11px rgba(148, 148, 148, .16);
  &-title{
      padding: 1rem 1.5rem;
      cursor: pointer;
      transition: all .2s;
      border-radius:15px;
  }
  &:hover, &.active{
    .subject-title{
      background-color: rgba(85, 124, 132, .25);
    }
  }
}

.lecturer {
  margin-top: 6px;
}

</style>
