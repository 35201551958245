<template>
  <div>
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="survey-law-subjects-modal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="xl">
      <!-- Modal content -->
      <div class="text-white content text-left">
        <p>ძვირფასო სტუდენტებო,&nbsp;</p>
        <p>ნელ-ნელა ვბრუნდებით ჩვეულ რეჟიმში და ვიწყებთ გაზაფხულის სემესტრის ცხრილის რეგისტრაციებზე გაშვებას. &nbsp;</p>
        <p>ცხრილის უკეთ ორგანიზებისთვის გვჭირდება თქვენი მოსაზრების გაგებაც იმის თაობაზე, თუ <strong>სამართლის სპეციალობის </strong>რომელ საგანი გესაჭიროებათ დისტანციურად. &nbsp;</p>
        <p><strong>გაცნობებთ, რომ გამოკითხვის შედეგები ჩვენთვის ავტომატურად სავალდებულო არ იქნება გასათვალისწინებლად.</strong> ჯგუფების ფორმატი (აუდიტორული ან დისტანციური) დამოკიდებულია ბევრ ფაქტორზე, მათ შორისაა თქვენი საჭიროებაც. გამოკითხვის შედეგები ჩვენთვის საორგანიზაციო-სარეკომენდაციო ხასიათის იქნება. &nbsp;</p>
        <p>შეგახსენებთ, რომ გამოკითხვა ეხება <strong>მხოლოდ სამართლის სპეციალობის საგნებს,</strong> რადგან მათზე ბევრი სტუდენტი რეგისტრირდება და ამის დაგეგმვას გარკვეული მონაცემები ჭირდება.&nbsp;</p>
        <p>გთხოვთ დროულად მიიღოთ მონაწილეობა ამ გამოკითხვაში, რომელიც 2022 წლის 11 თებერვლამდე გაგრძელდება. &nbsp;</p>
      </div>
      <b-form-checkbox-group
        v-model="selected"
        class="row">
        <div  class="col-12 col-md-6 text-left" v-for="subject of subjects">
          <b-form-checkbox :value="subject.subjectID">{{ subject.subject }}</b-form-checkbox>
        </div>
      </b-form-checkbox-group>
      <div class="text-white content text-left">
        <p>დიდი მადლობა ჩართულობისთვის,&nbsp;</p>
        <p>წარმატებებს გისურვებთ!&nbsp;</p>
        <p>კეთილი სურვილებით,&nbsp;</p>
        <p>სამართლის სკოლის ადმინისტრაცია</p>
      </div>
      <div class="mt-2 text-center">
        <button class="btn btn-light-bold mr-4" @click="handleSubmit()">შენახვა</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'survey-law-subjects-modal',
  data() {
    return {
      subjects: [],
      selected: []
    }
  },

  mounted() {
    this.loadSubjects();
  },

  methods: {
    showModal() {
      this.$bvModal.show('survey-law-subjects-modal');
    },
    close() {
      this.$bvModal.hide('survey-law-subjects-modal');
    },

    loadSubjects() {
      axios.get('/api/subjects')
      .then((response => {
        this.subjects = response.data.data

        if (this.subjects.length > 0) {
          this.showModal()
        }
      }))
    },

    handleSubmit() {
      axios.post('/api/subjects', {
        subjects: this.selected
      })
      .then(response => {
        this.close();
      })
    }
  }
};
</script>
<style scoped>
  .content {
    font-size: 1.6rem;
  }
</style>
