<template>
  <div class="registration-group-table">
    
    <div class="row justify-content-end">
      <div class="col-4">
    <button v-if="(goChoseAnotherSubject && subjectChangeButtonAppear) || hasChooseMinor" class="btn btn-light-bold p-3" style="cursor:pointer; font-size:20px" @click="handleChangeSubject(subject.subjectID)">{{$t('registration.changeSubject')}}</button>
      </div>  
  </div>
    <sk-list v-if="loading" height="0.02" :items-count="4"></sk-list>
    <div v-else-if="groups && groups.length === 0">
      <h3>{{ $t('registration.groups-not-found') }}</h3>
      <div class="mt-4">
        <send-request-modal :subject="subject"></send-request-modal>
      </div>
    </div>
 
    
    <div v-else>
      <h5 class="mb-5">{{ helpers.getLocalizedField(subject, 'subject') }}</h5>
 
       

       
        <table class="table table-responsive-lg">
          <thead>
            <tr>
              <th>{{ $t('registration.group') }}</th>
              <th>{{ $t('registration.day') }}</th>
              <th>{{ $t('registration.time') }}</th>
              <th>{{ $t('registration.lecturer') }}</th>
              <th style="width: 50px">{{ $t('registration.count') }}</th>
              <th>{{ $t('registration.curation_dates') }}</th>
              <th>{{ $t('registration.room') }}</th>
              <th>{{ $t('registration.study_start_date') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr @click="handleGroupClick(group, i,goChoseAnotherSubject)" :class="['subject-group', { 'border-bottom': !sameAsNextGroup(i) }, { 'invalid-date': !isValidDate(i) }]" v-for="(group, i) of groups">
              <td>{{ group.group }}</td>
              <td>{{ helpers.getLocalizedField(group, 'day') }}</td>
              <td>{{ group.time + ' - ' + group.time_end }}</td>
              <td>{{ helpers.getLocalizedField(group, 'name') }}</td>
              <td>{{ helpers.getLocalizedField(group, 'st_count') }}</td>
              <td>
                <span v-if="group.start_date !== '' && group.start_date !== null">{{ group.start_date + ' - ' + group.end_date }}</span>
              </td>
              <!--                    <td>{{ group.holddate }}</td>-->
              <td v-if="group.start_date && group.start_date !== ''">
                {{ $t('registration.curation') }}, {{ helpers.getLocalizedField(group, 'room') }}
              </td>
              <td v-else-if="group.room">{{ helpers.getLocalizedField(group, 'room') }}</td>
              <td v-else>{{ $t('registration.distance-room') }}</td>
              <td>{{ group.dates }}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
  import SkList from '../skeletons/sk-list';
  import SendRequestModal from './send-request-modal';
  import { mapGetters } from 'vuex';
  export default {
    name: 'groups-table',

    props: ['loading', 'subject', 'groups', 'already-registered', 'wants-change', 'parent-groups', 'from'],

    components: { SendRequestModal, SkList },

    data() {
      return {
        invalidDates: [],
        goChoseAnotherSubject:false,
        hasChooseMinor:0
      }
    },

    computed:{
      ...mapGetters({

        subjectChangeButtonAppear:'registration/subjectChangeButtonAppear'


      })


    },


    mounted(){

      this.$store.dispatch('registration/hasChooseMinor').then(response=>{

        this.hasChooseMinor=response.data
      })



    },

   
    watch: {
      groups(val) {
        this.invalidDates = [];
        this.validateDates();

          
        this.$store.dispatch('registration/getPreRegSubjects').then(response=>{

   
              

          if(response.data.find((o)=>{ return o.subjectID==this.subject.subjectID}) && val.length==0){
            this.goChoseAnotherSubject=true

            

          }



          })





      }
    },

    methods: {





      handleGroupClick(group, index,goChoseAnotherSubject) {
        if(!this.isValidDate(index)) {
          this.$swal({
            title: "",
            text: this.$t('registration.messages.duration-warning'),
            icon: "error",
            showConfirmButton: false,
            showCancelButton: false,
            confirmButtonText: false,
            cancelButtonText: false
          });

          return;
        }

        this.$emit('choose', group);
        this.$emit('goChoseAnotherSubject', goChoseAnotherSubject);
      },

      sameAsNextGroup(index) {
       
        return !this.groups[index + 1] || this.groups[index].group === this.groups[index + 1].group;
      },

      isValidDate(groupIndex) {

        

          

          if((this.invalidDates.indexOf(groupIndex) !== -1 && this.invalidDates.length==this.groups.length)){

            this.goChoseAnotherSubject=true

          }
 
        return this.invalidDates.indexOf(groupIndex) === -1;
      },

      validateDates() {
        if(!this.alreadyRegistered) return;

        let alreadyRegisteredGroups = Object.assign([], this.alreadyRegistered);

        if(this.parentGroups)
          for(let group of this.parentGroups) alreadyRegisteredGroups.push(group);

        if(this.wantsChange) {
          let wantsChange = alreadyRegisteredGroups.find(o => o.group == this.wantsChange);
          for(let group of alreadyRegisteredGroups) {
            if(wantsChange && group.subjectID === wantsChange.subjectID)
              alreadyRegisteredGroups = alreadyRegisteredGroups.filter(o => o.subjectID != wantsChange.subjectID);
          }
        }

        for(let [index, group] of this.groups.entries()) {

         

          for(let registeredGroup of alreadyRegisteredGroups) {
            const isCuration = group.start_date && group.start_date !== '' && registeredGroup.start_date && registeredGroup.start_date !== '';

            if(group.day === registeredGroup.day &&
              (this.helpers.isDateBetween(group.time, [registeredGroup.time, registeredGroup.time_end]) ||
                this.helpers.isDateBetween(group.time_end, [registeredGroup.time, registeredGroup.time_end]))  ||  group.start_date &&     (this.helpers.isDateBetween(group.time, [registeredGroup.time, registeredGroup.time_end]) ||
                this.helpers.isDateBetween(group.time_end, [registeredGroup.time, registeredGroup.time_end])) || registeredGroup.start_date &&     (this.helpers.isDateBetween(group.time, [registeredGroup.time, registeredGroup.time_end]) ||
                this.helpers.isDateBetween(group.time_end, [registeredGroup.time, registeredGroup.time_end]))) {

              const isCurationOverlay = (this.helpers.isDateBetween(group.start_date, [registeredGroup.start_date, registeredGroup.end_date]) ||
                                        this.helpers.isDateBetween(group.end_date, [registeredGroup.start_date, registeredGroup.end_date]));
              if(!isCuration || isCurationOverlay)
                this.invalidDates.push(index);
            }
          }
        }
      },

      handleChangeSubject(subjectID){
            

            this.$store.dispatch('registration/setmustChangePreRegSubject',subjectID)


     //   alert(subjectID)


      }
    }
  };
</script>

<style scoped>
  .invalid-date td {
      color: #c15146 !important;
  }

  .border-bottom {
    border-bottom: 1px solid #fff;
  }

  .subject-group {
    cursor: pointer;
  }

  .subject-group:hover {
    background-color: rgba(255, 255, 255, .2);
  }

  thead th{
    vertical-align: top !important;
  }
</style>
