<template>
    <div>
      <b-modal
        :hide-header="true"
        :hide-footer="true"
        content-class="modal-blue"
        id="informatics"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        :hide-header-close="true"
        size="lg">
        <!-- Modal content -->
        <h3 class="text-center"> </h3>
        <div class="row text-white justify-content-center">
            <div class="col-12">
                
                    <p>
                        ძვირფასო სტუდენტებო!<br>

                ტრადიციულად ყოველ წლიურად, ინფორმატიკის დეპარტამენტს სურს რომ ამ საკმაოდ მძიმე პერიოდშიც ცოტა გაგახალისოთ და ახალი წლის დადგმომადე ჩვენი ყურადღება გამოვხატოთ თქვენს მიმართ. გვინდა პატარა საჩუქრებით მოგილოცოთ ახალი წლის დადგომა. იმისთვის რომ კომუნიკაცია იყოს თქვენთან სწრაფი და სწორი, გთხოვთ შეავსოთ თქვენი სწორი მისამართი და ტელეფონის ნომერი 
                    </p>
  
  </div>
        </div>


        <div class="row text-white justify-content-center">
            <div class="col-6">
                
              <form>

                <div class="mb-2">
                        <input class="form-control" type="number" v-model="phoneNumber" placeholder="ტელეფონის ნომერი"/>
                    </div>
                    <div class="mb-2">
                        <input class="form-control" type="text" v-model="address" placeholder="მისამართი"/>
                    </div>
                    <div class="mb-2">
                    <button v-if="phoneNumber && address" type="button" @click="saveInformaticsInfo()" :disabled="phoneNumber.length!=9" class="btn btn-secondary">{{ $t('financial-info.save') }}</button>
                     </div>   


              </form>
  
  </div>
        </div>



      </b-modal>
  
    </div>
  </template>
  
  <script>

import FinancialInfoResource from '../api/financialInfoResource'; 
const financialInfoResource = new FinancialInfoResource();
   export default{
        data(){
            return {

                phoneNumber:null,
                address:null

            }
            
        },
        methods:{

            saveInformaticsInfo(){

                financialInfoResource.saveInformaticsInfo(this.phoneNumber,this.address).then(()=>{
                 location.reload()
                })

            }

        }

   }
  
  
  </script>
  
   
   