import axios from 'axios';
import { commonIconProps } from 'bootstrap-vue/esm/icons/helpers/icon-base';

export default {
  namespaced: true,

  state: {
    alreadyRegistered: null,
    ectsCurrentSemester: 0,
    programModules: {
      data: [],
      loading: false
    },
    registrationBlocks: [],
  },

  getters: {
    alreadyRegistered: state => state.alreadyRegistered,
    programModules: state => state.programModules,
    ectsCurrentSemester: state => state.ectsCurrentSemester,
  },

  mutations: {
    loadProgramModuleSubjects(state, data) {
      // eslint-disable-next-line no-param-reassign
      const programModule = state.programModules.data[data.programModuleIdx];

      programModule.subjects = data.subjects;
    },
    loadProgramModules(state, data) {
      // eslint-disable-next-line no-param-reassign
      for(const pm of data) {
        pm.subjects = [];
      }

      state.programModules.data = data;
      state.programModules.loading = false;
    },
  },

  actions: {
    loadRegistrationGuide({}, programID) {
      return new Promise((resolve) => {
        axios.get('/api/pre-registration/guide/'+programID)
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadAlreadyRegistered({ state }) {
      return new Promise((resolve) => {
        axios.get('/api/pre-registration/already-registered')
          .then((resp) => {
            state.alreadyRegistered = resp.data.data.subjects;
            state.ectsCurrentSemester = resp.data.data.total_ects;
            resolve(resp);
          });
      });
    },

    loadMinorSubjects() {
      return new Promise((resolve) => {
        axios.get('/api/pre-registration/minor-subjects')
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadFreeCreditSubjects({ state }) {
      return new Promise((resolve) => {
        axios.get('/api/pre-registration/free-subjects')
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadProgramModules({ commit, state }) {
      state.programModules.loading = true;
      return new Promise((resolve) => {
        axios.get('/api/pre-registration/program-modules')
          .then((resp) => {
            commit('loadProgramModules', resp.data.data);
            resolve(resp);
          });
      });
    },

    loadProgramModuleBlocks({ commit, state }, programModuleIdx) {
      return new Promise((resolve) => {
        axios.get('/api/pre-registration/program-modules/block-subjects/' + state.programModules.data[programModuleIdx].program_moduleID)
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadProgramModuleSubjects({ commit, state }, programModuleIdx) {
      return new Promise((resolve) => {
        const concentrationID = state.programModules.data[programModuleIdx].concentrationID;
        const concentrationParam = concentrationID ? '/' + concentrationID : '';

        axios.get('/api/pre-registration/program-modules/' + state.programModules.data[programModuleIdx].program_moduleID + concentrationParam)
          .then((resp) => {
            commit('loadProgramModuleSubjects', {
              subjects: resp.data.data,
              programModuleIdx: programModuleIdx
            });
            resolve(resp);
          });
      });
    },

    register({ state }, { subjectId }) {
      return new Promise((resolve, reject) => {
        axios.post('/api/pre-registration', {
          subject_id: subjectId
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    removeSubject({}, subjectID) {
      return new Promise((resolve, reject) => {
        axios.delete('/api/pre-registration/' + subjectID)
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getSubject({}, subjectID) {
      return new Promise((resolve, reject) => {
        axios.get('/api/subjects/' + subjectID)
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    
    getPreRegisteredSubjectsToShow(){
      return axios.get('/api/pre-registration/getPreRegisteredSubjectsToShow')
    },


    loadMinorPrograms() {
      return new Promise((resolve) => {
        axios.get('/api/pre-registration/minor-programs')
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    chooseMinorPrograms({}, programID) {
      
      if(!programID) programID = '';
      else programID = '/' + programID;

      return new Promise((resolve, reject) => {
        axios.post('/api/pre-registration/minor-programs' + programID)
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    
    hasChooseMinor({state}){

      return axios.get('/api/pre-registration/hasChooseMinor')


    },
  },
};
