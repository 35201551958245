import axios from 'axios';

export default class ClubResource {
  clubs() {
    return axios.get('/api/clubs')
  }

  rules() {
    return axios.get('/api/clubs/rules')
  }

  clubDetails() {
    return axios.get('/api/clubs')
  }

  createClub(formData) {
    return axios.post('/api/clubs', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  updateClub(clubId, formData) {
    return axios.post(`/api/clubs/${clubId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  projects(clubId) {
    return axios.get(`/api/clubs/${clubId}/my-projects`)
  }

  projectDetails(clubId, projectId) {
    return axios.get(`/api/clubs/${clubId}/my-projects/${projectId}`)
  }

  createProject(clubId, form) {
    return axios.post(`/api/clubs/${clubId}/my-projects`, form)
  }

  updateProject(clubId, projectId, form) {
    return axios.patch(`/api/clubs/${clubId}/my-projects/${projectId}`, form)
  }

  deleteProject(clubId, projectId) {
    return axios.delete(`/api/clubs/${clubId}/my-projects/${projectId}`)
  }

  projectResources(clubId, projectId) {
    return axios.get(`/api/clubs/${clubId}/my-projects/${projectId}/resources`)
  }

  createProjectResource(clubId, projectId, form) {
    return axios.post(`/api/clubs/${clubId}/my-projects/${projectId}/resources`, form)
  }

  deleteProjectResource(clubId, projectId, resourceId) {
      return axios.delete(`/api/clubs/${clubId}/my-projects/${projectId}/resources/${resourceId}`)
  }


  handleAnswer(self_governmentID,answer){

    return axios.post('/api/clubs/handleAnswer',{self_governmentID,answer})
  }
}
