import axios from 'axios';
import { commonIconProps } from 'bootstrap-vue/esm/icons/helpers/icon-base';

export default {
  namespaced: true,

  state: {
    alreadyRegistered: null,
    ectsCurrentSemester: 0,
    programModules: {
      data: [],
      loading: false
    },
    registrationBlocks: [],
    mustChangeGroups: [],
    mustChangePreRegSubject:null,
    changedPreRegSubject:null,
    preRegSubject:null,
    subjectChangeButtonAppear:true
  },

  getters: {
    alreadyRegistered: state => state.alreadyRegistered,
    programModules: state => state.programModules,
    ectsCurrentSemester: state => state.ectsCurrentSemester,
    mustChangeGroups: state => state.mustChangeGroups,
    mustChangePreRegSubject: state=>state.mustChangePreRegSubject,
    changedPreRegSubject:state=>state.changedPreRegSubject,
    preRegSubject:state=>state.preRegSubject,
    subjectChangeButtonAppear:state=>state.subjectChangeButtonAppear

  },

  mutations: {
    loadProgramModuleSubjects(state, data) {
      // eslint-disable-next-line no-param-reassign
      const programModule = state.programModules.data[data.programModuleIdx];

      programModule.subjects = data.subjects;
    },
    loadProgramModules(state, data) {
      // eslint-disable-next-line no-param-reassign
      for(const pm of data) {
        pm.subjects = [];
      }

      state.programModules.data = data;
      state.programModules.loading = false;
    },
  },

  actions: {

    setmustChangePreRegSubject({state},subjectID){

      
       
      state.mustChangePreRegSubject=subjectID

    },

    setChangedPreRegSubject({state},subjectID){

      
       
      state.changedPreRegSubject=subjectID

    },

    setpreRegSubject({state},subject){

 
       
       state.preRegSubject=subject.subject

    },

    setsubjectChangeButtonAppear({state},theState){

 
       
      state.subjectChangeButtonAppear=theState

   },



     


    getProgramSubjects(){

      return axios.get('/api/registration/getSubjects')

    },

    getPreRegSubjects(){

      return axios.get('/api/registration/getPreRegSubjects')

    },

    getAllPreRegSubjects(){

      return axios.get('/api/registration/getAllPreRegSubjects')

    },


    program_module_restrictions({state},program_moduleID){

      return axios.post('/api/registration/program_module_restriction',{program_moduleID})

    },


    dontWantFromFreeCredit({state}){
      return axios.post('/api/registration/dontWantFromFreeCredit')

    },



    hasChooseMinor({state}){

      return axios.get('/api/registration/hasChooseMinor')


    },
 

    getFilteredPreRegSubject({state},subjectID){

        return axios.get('/api/registration/getFilteredPreRegSubject/'+subjectID)

    },


    isRegistered({state},subjectID){

      return axios.post('/api/registration/isRegistered',subjectID)

    },

    loadMustChangeGroups({ state }) {
      return new Promise((resolve) => {
        axios.get('/api/registration/must-change-group')
          .then((resp) => {
            state.mustChangeGroups = resp.data.data;
            resolve(resp);
          });
      });
    },

    loadRegistrationGuide({}, programID) {
      return new Promise((resolve) => {
        axios.get('/api/registration/guide/'+programID)
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadAlreadyRegistered({ state }) {
      return new Promise((resolve) => {
        axios.get('/api/registration/already-registered')
          .then((resp) => {
            state.alreadyRegistered = resp.data.data.subjects;
            state.ectsCurrentSemester = resp.data.data.total_ects;
            resolve(resp);
          });
      });
    },

    loadFreeCreditSubjects({ state }) {
      return new Promise((resolve) => {
        axios.get('/api/registration/free-subjects')
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadProgramModules({ commit, state }) {
      state.programModules.loading = true;
      return new Promise((resolve) => {
        axios.get('/api/registration/program-modules')
          .then((resp) => {
            commit('loadProgramModules', resp.data.data);
            resolve(resp);
          });
      });
    },

    loadProgramModuleBlocks({ commit, state }, programModuleIdx) {
      return new Promise((resolve) => {
        axios.get('/api/registration/program-modules/block-subjects/' + state.programModules.data[programModuleIdx].program_moduleID)
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadProgramModuleSubjects({ commit, state }, programModuleIdx) {
      return new Promise((resolve) => {
        const concentrationID = state.programModules.data[programModuleIdx].concentrationID;
        const concentrationParam = concentrationID ? '/' + concentrationID : '';

        axios.get('/api/registration/program-modules/' + state.programModules.data[programModuleIdx].program_moduleID + concentrationParam)
          .then((resp) => {
            commit('loadProgramModuleSubjects', {
              subjects: resp.data.data,
              programModuleIdx: programModuleIdx
            });
            resolve(resp);
          });
      });
    },

    loadMinorPrograms() {
      return new Promise((resolve) => {
        axios.get('/api/registration/minor-programs')
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadMinorSubjects() {
      return new Promise((resolve) => {
        axios.get('/api/registration/minor-subjects')
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    chooseMinorPrograms({}, programID) {
      if(!programID) programID = '';
      else programID = '/' + programID;

      return new Promise((resolve, reject) => {
        axios.post('/api/registration/minor-programs' + programID)
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getSubjectGroups({}, { subjectID,mustChangePreRegSubject }) {

      if(mustChangePreRegSubject){

       var additionUrl='/'+mustChangePreRegSubject

      }else {

       var additionUrl=''
      }


      return new Promise((resolve, reject) => {
             


        axios.get('/api/registration/groups/general/' + subjectID+additionUrl)
          .then((resp) => {


            
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getSubjectPractiseGroups({}, { subjectID }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/registration/groups/practise/' + subjectID)
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getSubjectSeminarGroups({}, { scheduleID }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/registration/groups/seminar/' + scheduleID)
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    registerPractise({}, { group, practiseGroup }) {
      return new Promise((resolve, reject) => {
        axios.post('/api/registration/practise', {
          group: group,
          practise_group: practiseGroup,
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    registerSeminar({}, { group, seminarGroup }) {
      return new Promise((resolve, reject) => {
        axios.post('/api/registration/seminar', {
          group: group,
          seminar_group: seminarGroup,
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    sendRequest({}, { subjectID, dayID, timeID }) {
      return new Promise((resolve, reject) => {
        axios.post('/api/registration/send-request', {
          subject_id: subjectID,
          day_id: dayID,
          time_id: timeID
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    register({}, { programModuleID, programID, group, seminarGroup, practiseGroup,old_subjectID,newSubjectID,hasChooseMinor ,preRegID}) {
      return new Promise((resolve, reject) => {
        axios.post('/api/registration', {
          program_module_id: programModuleID,
          program_id: programID,
          schedule_id: group ? group.scheduleID : '',
          practise_schedule_id: practiseGroup ? practiseGroup.scheduleID : '',
          seminar_schedule_id: seminarGroup ? seminarGroup.scheduleID : '',
          old_subjectID:old_subjectID?old_subjectID:0,
          newSubjectID:newSubjectID?newSubjectID:0,
          hasChooseMinor:hasChooseMinor,
          preRegID:preRegID
        })
          .then((resp) => {

             

            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    change({}, { programModuleID, programID, oldGroup, group, seminarGroup, practiseGroup }) {
      return new Promise((resolve, reject) => {
        axios.patch('/api/registration/' + group.subjectID, {
          program_module_id: programModuleID,
          program_id: programID,
          old_group: oldGroup ? oldGroup : '',
          schedule_id: group ? group.scheduleID : '',
          practise_schedule_id: practiseGroup ? practiseGroup.scheduleID : '',
          seminar_schedule_id: seminarGroup ? seminarGroup.scheduleID : '',
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    removeSubject({}, subjectID) {
      return new Promise((resolve, reject) => {
        axios.delete('/api/registration/' + subjectID)
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getSubject({}, subjectID) {
      return new Promise((resolve, reject) => {
        axios.get('/api/subjects/' + subjectID)
          .then((resp) => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },


        
    writeProblem({state},problemText){

      return axios.post('/api/registration/writeProblem',{problemText})

    }







  },
};
