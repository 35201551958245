<template>
  <div>
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="graduation-modal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="xl">
      <!-- Modal content -->
      <div class="row text-white content">

                    <div class="col-12">   {{$t('graduation.title')}}  </div>


                     <div class="col-12"> {{$t('graduation.buy')}} <input type="radio"  name="type" id="buy" class="radio" @click="chosenType('buy')"  />   </div>  
                     <div class="col-12"> {{$t('graduation.rent')}} <input type="radio"  name="type" id="rent" class="radio"  @click="chosenType('rent')"  />   </div>  




      </div>
    </b-modal>

  </div>
</template>

<script>
 import FinancialInfoResource from '../../api/financialInfoResource';
 const resource = new FinancialInfoResource();
export default {
            methods:{

                    chosenType(type){

                                if(confirm("ნამდვილად გსურთ არჩეული პასუხის შენახვა?/Are you sure you want to save the selected answer?")){

                                       

                                          if(type=='buy'){
                                            var amount=70
                                            var paymentType='MANT_BUY';
                                            var payForId=null;

                                          }else {

                                            var amount=30
                                            var paymentType='MANT_RENT';
                                            var payForId=null;


                                          }

                                              
                                          resource.getPaymentGatewayLink(amount, paymentType, payForId).then(response => {
                                                  

                                                  if(response.data.data.link.includes('https://ecommerce.ufc.ge/ecomm2/ClientHandler'))
                                                    window.location.href = response.data.data.link;
                                                  else
                                                    this.helpers.notifyErrorMessage(this.$t('errors.general'))
                                                })
                                                  .catch(err => {
                                                    this.helpers.notifyErrorMessage(err)
                                                  })




                                      


                                }    


                    }


            }


   
};
</script>

<style scoped>
.radio{
 height: 20px;
  width: 20px;
    

}

</style>
 