import axios from 'axios';

export default {
  namespaced: true,

  state: {
    contactInfo: {},
  },

  getters: {
    contactInfo: state => state.contactInfo,
  },

  mutations: {
    loadContactInfo(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.contactInfo = data;
    },
  },

  actions: {
    loadContactInfo({ commit }) {
      return new Promise((resolve) => {
        axios.get('/api/profile/contact-information')
          .then((resp) => {
            commit('loadContactInfo', resp.data.data);
            resolve(resp);
          });
      });
    },

    problem({state},formData){

      

      return axios({
        url: '/api/problems',
        method: 'POST',
        data: formData,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        }
      })


    }


  },
};
