import Vue from 'vue';
import Router from 'vue-router';
import VueBodyClass from 'vue-body-class';
import store from '../store';
 
Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../pages/home'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/profile'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../pages/schedule'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/evaluations',
    name: 'evaluations',
    component: () => import(/* webpackChunkName: "evaluations" */ '../pages/evaluations'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/academic-calendar',
    name: 'academic-calendar',
    component: () => import(/* webpackChunkName: "academic-calendar" */ '../pages/academic-calendar'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },

   
{
  path: '/registration-steps',
  name: 'registration steps',
  component: () => import(/* webpackChunkName: "registration" */ '../pages/registrationSteps'),
  meta: {
    requiresAuth: true,
    bodyClass: 'app main-bg',
   
}
},

  {
    path: '/registration',
    name: 'registration',
    component: () => import(/* webpackChunkName: "registration" */ '../pages/registration'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/pre-registration',
    name: 'pre-registration',
    component: () => import(/* webpackChunkName: "pre-registration" */ '../pages/pre-registration'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/exam-schedule',
    name: 'exam-schedule',
    component: () => import(/* webpackChunkName: "exam-schedule" */ '../pages/exam-schedule'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/exam-view',
    name: 'exam-view',
    component: () => import(/* webpackChunkName: "exam-view" */ '../pages/exam-view'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    name: 'exam-detail-view',
    path: '/exam-view/:id/:examScheduleID/:examNumberId',
    component: () => import(/* webpackChunkName: "exam-detail-view" */ '../pages/exam-detail-view'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/my-program',
    name: 'my-program',
    component: () => import(/* webpackChunkName: "my-program" */ '../pages/my-program'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/conversations',
    name: 'conversations',
    component: () => import(/* webpackChunkName: "conversation" */ '../pages/conversations'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },

  {
    path: '/pymentSuccess/:type',
    name: 'pymentSuccess',
    component: () => import(/* webpackChunkName: "login" */ '../pages/pymentSuccess'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },



  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login'),
  },
  {
    path: '/confirmation/:theString',
    name: 'confirmation',
    component: () => import(/* webpackChunkName: "login" */ '../pages/confirmation'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../pages/forgot-password'),
    meta: {
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../pages/logout')
  },
  {
    path: '/my-applications',
    name: 'my-applications',
    component: () => import(/* webpackChunkName: "my-applications" */ '../pages/my-applications'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/my-applications/new',
    name: 'new-application',
    component: () => import(/* webpackChunkName: "new-application" */ '../pages/new-application'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/lab',
    name: 'lab',
    component: () => import(/* webpackChunkName: "lab" */ '../pages/lab'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/lab/:unitId',
    name: 'lab-unit',
    component: () => import(/* webpackChunkName: "lab-unit" */ '../pages/lab-unit'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/presentations',
    name: 'presentations',
    component: () => import(/* webpackChunkName: "presentations" */ '../pages/presentations'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/financial-info',
    name: 'financial-info',
    component: () => import(/* webpackChunkName: "financial-info" */ '../pages/financial-info'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/pay-online',
    name: 'pay-online',
    component: () => import(/* webpackChunkName: "pay-online" */ '../pages/pay-online'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/master-themes',
    name: 'master-themes',
    component: () => import(/* webpackChunkName: "master-themes" */ '../pages/master-themes'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/my-library',
    name: 'my-library',
    component: () => import(/* webpackChunkName: "my-library" */ '../pages/my-library'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/online-books',
    name: 'online-books',
    component: () => import(/* webpackChunkName: "online-books" */ '../pages/online-books'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },

  {
    path: '/register_problems',
    name: 'register_problems',
    component: () => import(/* webpackChunkName: "online-books" */ '../pages/registerProblems'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },

  {
    path: '/medicine-topics',
    name: 'medicine-topics',
    component: () => import(/* webpackChunkName: "online-books" */ '../pages/medicineTopics.vue'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },


   
  {
    path: '/clubs/manage',
    name: 'clubs-manage',
    component: () => import(/* webpackChunkName: "club-manage" */ '../pages/clubs/manage'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/clubs',
    name: 'clubs',
    component: () => import(/* webpackChunkName: "clubs" */ '../pages/clubs'),
    children: [
      {
        path: '/clubs',
        name: 'club-list',
        component: () => import(/* webpackChunkName: "club-lists" */ '../components/clubs/club-list'),
      },
      {
        path: '/clubs/form',
        name: 'club-form',
        component: () => import(/* webpackChunkName: "club-form" */ '../components/clubs/club-form'),
      }
    ],
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/medicine-lab-groups',
    name: 'medicine-lab-groups',
    component: () => import(/* webpackChunkName: "medicine-lab-groups" */ '../pages/medicine-lab-groups'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import(/* webpackChunkName: "pay" */ '../pages/pay'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/university-charter',
    name: 'university-charter',
    component: () => import(/* webpackChunkName: "university-charter" */ '../pages/university-charter'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/buy-books',
    name: 'buy-books',
    component: () => import(/* webpackChunkName: "buy-books" */ '../pages/buy-books'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/buy-books/:bookId',
    name: 'buy-books-details',
    component: () => import(/* webpackChunkName: "buy-books-details" */ '../pages/buy-books-details'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/cam-requests',
    name: 'cam-requests',
    component: () => import(/* webpackChunkName: "cam-requests" */ '../pages/cam-requests'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/cinematography-cam-requests',
    name: 'cinematography-cam-requests',
    component: () => import(/* webpackChunkName: "cinematography-cam-requests" */ '../pages/cinematography-cam-requests'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/courses/:subjectId',
    name: 'course-details',
    component: () => import(/* webpackChunkName: "course-details" */ '../pages/course-details'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '/courses/:subjectId/quiz',
    name: 'short-term-quiz',
    component: () => import(/* webpackChunkName: "short-term-quiz" */ '../pages/short-term-quiz'),
    meta: {
      requiresAuth: true,
      bodyClass: 'app main-bg',
    },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "error-404" */ '../pages/error-404'),
  },
];

const router = new Router({
  mode: 'history',
  base: '/',
  routes
});

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next); });

router.beforeEach((to, from, next) => {
 


  if (to.matched.some(record => record.meta.requiresAuth)) {
 
    if (store.getters['auth/isLoggedIn']) {
       next();
      return;
    }

    next('/login');
  } else {
    next();
  }
});

 

export default router;
