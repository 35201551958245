import axios from 'axios';
import helpers from '../helpers';

export default {
  namespaced: true,

  state: {
    unreadConversations: 0,
    conversationList: {
      loading: false,
      data: [],
      searchKeyword: '',
      page: 1,
    },
    currentConversation: {
      conversation: null,
      loading: false,
      page: 1,
      messages: []
    }
  },

  getters: {
    conversationList: state => state.conversationList,
    unreadConversations: state => state.unreadConversations,
    currentConversation: state => state.currentConversation,
    conversationPicture(state) {
      const conversation = state.currentConversation.conversation;

      if(!conversation) return;

      if(conversation.is_group) return '/static/images/chat/group-hover.png';

      if(conversation.is_student == 1) return '/static/images/chat/student-hover.png';

      return '/static/images/chat/lecturer-hover.png';
    },
    conversationName(state) {
      const conversation = state.currentConversation.conversation;

      if(!conversation) return;

      let name = conversation.name;
      if(name) return helpers.getLocalizedField(conversation, 'name');

      if(conversation.fname && conversation.lname) {
        return helpers.getLocalizedField(conversation, 'fname') + ' ' + helpers.getLocalizedField(conversation, 'lname');
      }
    },
    conversationMembers(state) {
      // TODO
      return [];
    },
    conversationMessages(state) {
      return state.currentConversation.messages;
    }
  },

  mutations: {
    requestConversationList(state) {
      state.conversationList.loading = true;
    },

    loadConversationListSuccess(state, data) {
      if(state.conversationList.searchKeyword === data.keyword && state.conversationList.page !== data.page) {
        state.conversationList.data = state.conversationList.data.concat(data.data);
      } else {
        state.conversationList.data = data.data;
      }

      // eslint-disable-next-line no-param-reassign
      state.conversationList.loading = false;
      state.conversationList.searchKeyword = data.keyword;
      state.conversationList.page = data.page;
    },

    loadConversationListError(state) {
      // eslint-disable-next-line no-param-reassign
      state.conversationList.loading = false;
      state.conversationList.page = 1;
      state.conversationList.data = [];
    },

    selectConversation(state, conversation) {

 
      state.currentConversation.conversation = conversation;
      state.currentConversation.messages = [];
    },

    requestConversationMessages(state) {
      state.currentConversation.loading = true;
    },

    loadConversationMessages(state, data) {
      const messages = helpers.sortArrayByObjectValue(Object.values(data.messages), 'created_at');

      if(data.page === 1) state.currentConversation.messages = messages;

      if(data.page > 1) {
        for(let message of messages.reverse()) {
          state.currentConversation.messages.unshift(message);
        }
      }

      state.currentConversation.page = data.page;
      state.currentConversation.loading = false;
    },

    messageSent(state, data) {
      // state.currentConversation.messages.push(data);
    },

    messageSeen(state, data) {
      let messages = state.currentConversation.messages.filter(o => o.conversation_messageID <= data.conversation_messageID);

      for(let message of messages) {
        if(message.seenBy.indexOf(data.user_code) === -1) message.seenBy.push(data.user_code);
      }
    }
  },

  actions: {
    loadCounts({ state }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/conversations/count').then((response) => {
          state.unreadConversations = response.data.data.count;
          resolve(response);
        })
          .catch(error => {
            reject(error);
          });
      });
    },

    addMessage({ state, dispatch }, message) {
      let conversation = state.conversationList.data.find(o => o.conversationID == message.conversationID);

      const currentConversation = state.currentConversation.conversation;
      if(currentConversation && conversation && conversation.conversationID == currentConversation.conversationID) {
        conversation.last_message = message;
        state.currentConversation.messages.push(message);
      } else if(conversation) {
        conversation.last_message = message;
        conversation.new_messages_count++;

        if(!conversation.newMessages)
          conversation.newMessages = [];
        conversation.newMessages.push(message);
      } else {
         
        dispatch('loadConversationList').then((response) => {

          
          dispatch('conversationSelected', response.data.data[0]);
        });
      }
    },

    setSeen({ commit, state }, data) {
      return new Promise(resolve => {
        axios.get('/api/conversations/'+state.currentConversation.conversation.conversationID+'/messages/'+data.message.conversation_messageID+'/seen')
          .then(response => {
            commit('messageSeen', response.data.data);
            resolve(response)
          })
      })
    },

    getFormData(data, attachments) {
      const formData = new FormData();

      formData.append('text', data.text);
      formData.append('recipient_code', data.recipient_code);

      for(const attachment of attachments) {
        formData.append('files[]', attachment);
      }

      return formData;
    },

    sendMessage({ commit, state }, { message, attachments }) {
      const conversation = state.currentConversation.conversation;
      const conversationID = conversation && conversation.conversationID ? '/'+conversation.conversationID : '';

      const formData = new FormData();
      formData.append('text', message);
      formData.append('recipient_code', conversationID ? null : state.currentConversation.conversation.code);
      for(const attachment of attachments) {
        formData.append('files[]', attachment);
      }

      return new Promise((resolve) => {
        axios.post('/api/conversations' + conversationID, formData)
          .then((response) => {
            commit('messageSent', response.data.data);
            resolve(response);
          });
      });
    },

    loadConversationMessages({ commit, state }, data) {
      commit('requestConversationMessages');

    

      const page = data && data.page ? data.page : 1;
      const conversationID= data && data.conversationID?data.conversationID:state.currentConversation.conversation.conversationID


    

      return new Promise((resolve) => {
        axios.get('/api/conversations/'+conversationID+'/messages?page=' + page)
          .then((response) => {
            commit('loadConversationMessages', {
              messages: response.data.data,
              page: page
            });
            resolve(response);
          });
      });
    },

    conversationSelected({ dispatch, commit, state }, data) {

    
      commit('selectConversation', data);

      if(data.conversationID) {
        return new Promise(resolve => {
          dispatch('loadConversationMessages').then(() => {
            resolve();
          });
        });
      }
    },

    loadConversationList({ commit }, data) {
 
      const searchKeyword = data && data.keyword ? data.keyword : '';
      const page = data && data.page ? data.page : 1;

      commit('requestConversationList');

      return new Promise((resolve) => {
        axios.get('/api/conversations/list?page=' + page + '&search=' + searchKeyword)
          .then((response) => {

            
            commit('loadConversationListSuccess', {
              data: response.data.data,
              keyword: searchKeyword,
              page: page
            });
            resolve(response);
          })
          .catch((e) => {
            commit('loadConversationListError');
          });
      });
    },
  },
};
