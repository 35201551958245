<template>
  <div>
    <b-modal
      @shown="onShown()"
      :hide-header="true"
      :hide-footer="true"
      :no-close-on-esc="true"
     :no-close-on-backdrop="true"
      content-class="modal-blue"
      id="practise-groups-modal" 
      :hide-header-close="true"
      size="xl">
        <!-- Modal content-->
        <div class="row ">
          <div class="col mb-5 pb-5 ">
            <h4 class="mb-5">{{ $t('registration.choose-practise-group') }}</h4>
            <groups-table @choose="handleGroupClick" :wants-change="wantsChange" :parent-groups="parentGroups" :already-registered="alreadyRegistered" :loading="loading" :subject="subject" :groups="groups"></groups-table>
          </div>
        </div>
        <div class="row">
          <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
            <button v-if="!disabled" type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('registration.close') }}</button>
          </div>
        </div>
    </b-modal>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SkList from '../skeletons/sk-list';
  import GroupsTable from './groups-table';

  export default {
    name: 'practise-groups-modal',
    components: { GroupsTable, SkList },

    props: ['subject', 'group', 'seminar', 'already-registered', 'wants-change', 'disabled'],

    computed: {
      parentGroups() {
        return [this.group, this.seminar];
      }
    },

    data() {
      return {
        loading: true,
        groups: [],
      }
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('practise-groups-modal');
        this.$emit('closed');
        this.groups = [];
        location.reload()
      },

      onShown() {
        this.$store.dispatch('registration/getSubjectPractiseGroups', {
          subjectID: this.subject.subjectID
        })
          .then(response => {
            this.groups = response.data.data;
            this.loading = false;
          })
          .catch(error => {
            this.hideModal();
            this.helpers.notifyErrorMessage(error);
          })
      },

      handleGroupClick(group) {
        this.$swal({
          title: "",
          text: this.$t('registration.confirm-registration'),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: this.$t('registration.minor-modal.ok'),
          cancelButtonText: this.$t('registration.minor-modal.cancel'),
          closeOnConfirm: false,
          closeOnCancel: false
        }).then((result) => {
          if(result && result.isConfirmed) this.$emit('register-practise', group);
        });
      },
    }
  };
</script>
