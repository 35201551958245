<template>
  <skeleton theme="opacity" shape="radius" bg-color="#dcdbdc" class="p-3">
      <tb-skeleton class="mt-2" :key="i" v-for="i in itemsCount" :aspect-ratio="height"></tb-skeleton>
  </skeleton>
</template>

<script>
  export default {
    name: 'sk-list',

    props: ['height', 'items-count']
  };
</script>
