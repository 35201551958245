<template>
  <div class="d-inline-flex language align-items-center">
    <a href="#" class="nav-link px-0 py-3 my-1" @click="setLocale('ka')">ქარ</a>
    <a href="#" class="nav-link px-0 py-3 my-1 pr-2" @click="setLocale('en')">ENG</a>
  </div>
</template>

<script>
  export default {
    name: 'language-switcher',

    methods: {
      setLocale(locale) {
        this.$store.dispatch('language/setLocale', locale);
        this.$emit('changed-locale', locale);
      },
    },
  };
</script>

<style scoped>

</style>
