import axios from 'axios';
import i18n from './i18n';
import Vue from 'vue';
import moment, { isMoment } from 'moment';

function getLocalizedField(object, labelKa, labelEn) {
  if (i18n.locale === 'en' && labelEn && object[labelEn] !== '') return object[labelEn];

  if (i18n.locale === 'en' && object[`${labelKa}_eng`] && object[`${labelKa}_eng`] !== '') return object[`${labelKa}_eng`];

  if (i18n.locale === 'en' && object[`${labelKa}eng`] && object[`${labelKa}eng`] !== '') return object[`${labelKa}eng`];

  if (i18n.locale === 'en' && object[`${labelKa}Eng`] && object[`${labelKa}Eng`] !== '') return object[`${labelKa}Eng`];

  return object[labelKa];
}

function getStudentPicture() {
  return axios({
    method: 'get',
    url: '/api/profile/picture',
    headers: {
      'Content-type': 'image/jpeg',
    },
  });
}

function getStudentTitle(student) {
  return `${getLocalizedField(student, 'school')}<br />${getLocalizedField(student, 'speciality')}`;
}

function notifyMessage(html,
  title = '',
  icon = '',
  showConfirmButton = false,
  confirmButtonText = '',
  showCancelButton = false,
  cancelButtonText = '') {
  Vue.swal({
    title,
    html,
    icon,
    showConfirmButton,
    showCancelButton,
    confirmButtonText,
    cancelButtonText
  })
}

function notifySuccessMessage(message,
  showConfirmButton = false,
  confirmButtonText = '') {
  notifyMessage(message, '', 'success', showConfirmButton, confirmButtonText)
}

function notifyErrorMessage(error,
  showConfirmButton = false,
  confirmButtonText = '') {
  if(error.response && error.response.data && error.response.data.message) {
    const message = error.response.data.message;

    if(message === 'validation_errors') {
      let text = [];
      for(let key in error.response.data.data) {
        const errorData = error.response.data.data[key];
        text.push(errorData.join('<br/>'));
      }

      let html = '';
      for(let item of text) {
        html += '<div>&bull; '+item+'</div>';
      }
      notifyMessage(html, '', 'warning', showConfirmButton, confirmButtonText)
    } else {
      notifyMessage(message, '', 'error', showConfirmButton, confirmButtonText)
    }
  } else if(typeof error === 'string' || error instanceof String) {
    notifyMessage(error, '', 'error', showConfirmButton, confirmButtonText)
  } else {
    notifyMessage(i18n.t('errors.general'), '', 'error', showConfirmButton, confirmButtonText)
  }
}

function notifyWarningMessage(error,
  showConfirmButton = false,
  confirmButtonText = '') {
  if(error.response && error.response.data && error.response.data.message) {
    const message = error.response.data.message;

    if(message === 'validation_errors') {
      let text = [];
      for(let key in error.response.data.data) {
        const errorData = error.response.data.data[key];
        text.push(errorData.join('<br/>'));
      }

      notifyMessage(text.join('<br/>'), '', 'warning', showConfirmButton, confirmButtonText)
    } else {
      notifyMessage(message, '', 'warning', showConfirmButton, confirmButtonText)
    }
  } else if(typeof error === 'string' || error instanceof String) {
    notifyMessage(error, '', 'warning', showConfirmButton, confirmButtonText)
  } else {
    notifyMessage(i18n.t('errors.general'), '', 'error', showConfirmButton, confirmButtonText)
  }
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

function highlightString(string, hlStr, hlClass) {
  hlStr = hlStr.trim();
  let index = string.indexOf(hlStr);
  if (index >= 0) {
    return string.substring(0,index) + "<span class='"+hlClass+"'>" + string.substring(index,index+hlStr.length) + "</span>" + string.substring(index + hlStr.length);
  }

  return string;
}

function sortArrayByObjectValue(array, objectKey, sortDir = 'desc') {
  return array.sort((a, b) => {
    if(sortDir === 'desc' && b[objectKey] < a[objectKey]) return 1;
    if(sortDir === 'desc' && b[objectKey] > a[objectKey]) return -1;

    if(sortDir === 'asc' && b[objectKey] > a[objectKey]) return 1;
    if(sortDir === 'asc' && b[objectKey] < a[objectKey]) return -1;

    if(b[objectKey] === a[objectKey]) return 0;
  });
}

function scrollChatWindowBottom() {
  const objDiv = document.querySelector(".conversation__wrapper");
  objDiv.scrollTop = objDiv.scrollHeight;
}

function getTime(time) {
  return moment(time, 'HH:mm:ss').format('HH:mm:ss');
}

function getDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

function getPercent(part, total) {
  return parseFloat(part / total * 100).toPrecision(2);
}

function getDistanceRoomID() {
  // TODO
  return 559;
}

function getSubjectIcon(evaluation, canRetake) {
  if(canRetake == 1) return '/static/images/icons/evaluations/recover-ic.png';

  return evaluation === 'F' ?
    '/static/images/icons/evaluations/fail-ic.png' :
    '/static/images/icons/evaluations/accept-ic.png';
}

function getSubjectSemester(subjectSemester) {
  if(!subjectSemester) return '';

  if(subjectSemester.length <= 2) return subjectSemester;

  let semesters = subjectSemester.split('/');

  if(semesters.length === 1) return semesters[0].substr(0, 1);

  return semesters[0].substr(0, 1) + '/' + semesters[1].substr(0, 1);
}

function getGroupNumber(group) {
  return group === '0' ? '-' : group;
}

function isDateBetween(date, [startDate, endDate]) {
  return date >= startDate && date <= endDate;
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function deleteCookie( name, path, domain ) {
  if(getCookie(name)  !== '') {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

function getDateDifference(date1, date2 = null) {
  if(!date2) date2 = moment();
  date1 = isMoment(date1) ? date1 : moment(date1);

  let diffInHours = date2.diff(date1, 'hours');
  let diffInMinutes = date2.diff(date1, 'minutes');

  if(diffInHours < 1) {
    diffInMinutes = diffInMinutes > 0 ? diffInMinutes : 1;
    const str = diffInMinutes > 1 ? i18n.t('notifications.minutes-ago') : i18n.t('notifications.minute-ago');
    return diffInMinutes + ' ' + str;
  }

  const str = diffInHours > 1 ? i18n.t('notifications.hours-ago') : i18n.t('notifications.hour-ago');
  return diffInHours + ' ' + str;
}

function copyToClipboard(text) {
  return navigator.clipboard.writeText(text);
}

function practiseLectureIds() {
  return [7,9,11,12];
}

function getApplicationIconPath(application) {
  let icon = '/static/images/icons/evaluations/fail.png';

  if(application.status == 0 && application.correspondence_number == 0) icon = '/static/images/icons/evaluations/recover.png';

  if(!application.status || (application.status == 0 && application.correspondence_number != 0)) icon = '/static/images/icons/evaluations/accept-ic.png';

  if(application.status == 4 || application.status == 7) icon = '/static/images/icons/evaluations/accept.png';

  if(application.status == 5) icon = '/static/images/icons/evaluations/fail-ic.png';

  if(application.status == 3) icon = '/static/images/icons/evaluations/fail.png';

  return icon;
}

function getApplicationProgressIconPath(application) {
  let icon = ''; 

  if(application.statusID==1) icon = '/static/images/icons/evaluations/accept-ic.png';

  if(application.statusID == 4 || application.statusID == 7) icon = '/static/images/icons/evaluations/accept.png';

  if(application.statusID == 5) icon = '/static/images/icons/evaluations/fail-ic.png';

  if(application.statusID == 3) icon = '/static/images/icons/evaluations/fail.png';

  return icon;
}





export default {
  getLocalizedField,
  getStudentPicture,
  isEmptyObject,
  notifyMessage,
  notifyWarningMessage,
  notifySuccessMessage,
  notifyErrorMessage,
  highlightString,
  sortArrayByObjectValue,
  scrollChatWindowBottom,
  getDistanceRoomID,
  getTime,
  getDate,
  getPercent,
  getSubjectIcon,
  getSubjectSemester,
  getGroupNumber,
  isDateBetween,
  getCookie,
  deleteCookie,
  getDateDifference,
  practiseLectureIds,
  getApplicationIconPath,
  getApplicationProgressIconPath
};
