import axios from 'axios';

export default {
  namespaced: true,

  state: {
    messages: [],
  },

  getters: {
    messages: state => state.messages,
  },

  mutations: {
    loadMessages(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.messages = data;
    },
  },

  actions: {
    loadMessages({ commit }) {
      return new Promise((resolve) => {
        axios.get('/api/popup-messages')
          .then((resp) => {
            commit('loadMessages', resp.data.data);
            resolve(resp);
          });
      });
    },

    seen({ state }, modalMessageID) {
      return new Promise((resolve) => {
        axios.post('/api/popup-messages/' + modalMessageID)
          .then((resp) => {
            state.messages.filter(o => o.modal_messageID == modalMessageID)[0].recipients[0].seen = true;
            resolve(resp);
          });
      });
    }
  },
};
