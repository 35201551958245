import axios from 'axios';

const HEADER_NAV_ITEMS_POSITION = 1;
const SIDEBAR_NAV_ITEMS_POSITION = 2;

export default {
  namespaced: true,

  state: {
    headerNavItems: [],
    headerMenuTree: [],
    sidebarNavItems: [],
  },

  getters: {
    headerNavItems: state => state.headerNavItems,
    sidebarNavItems: state => state.sidebarNavItems,
    headerMenuTree: state => state.headerMenuTree,
  },

  mutations: {
    loadHeaderNavItems(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.headerNavItems = data;

      const mainItems = data.filter(o => o.parentID === null)
        .sort((a, b) => a.positions[0].sort - b.positions[0].sort);

      // eslint-disable-next-line no-param-reassign
      state.headerMenuTree = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const navItem of mainItems) {
        // eslint-disable-next-line eqeqeq
        navItem.items = state.headerNavItems.filter(o => o.parentID == navItem.menuID)
          .sort((a, b) => a.positions[0].sort - b.positions[0].sort);

        state.headerMenuTree.push(navItem);
      }
    },

    loadSidebarNavItems(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.sidebarNavItems = data.sort((a, b) => a.positions[0].sort - b.positions[0].sort);
    },
  },

  actions: {
    loadHeaderNavItems({ commit }) {
      return new Promise((resolve) => {
        // commit('auth_request');
        axios.get(`/api/menu-items?position=${HEADER_NAV_ITEMS_POSITION}`)
          .then((resp) => {
            commit('loadHeaderNavItems', resp.data.data);
            resolve(resp);
          });
      });
    },

    loadSidebarNavItems({ commit }) {
      return new Promise((resolve) => {
        // commit('auth_request');
        axios.get(`/api/menu-items?position=${SIDEBAR_NAV_ITEMS_POSITION}`)
          .then((resp) => {
            commit('loadSidebarNavItems', resp.data.data);
            resolve(resp);
          });
      });
    },
  },
};
