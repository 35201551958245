<template>
  <div class="closed-question">
    <div :class="[{'row':question.additional==0}, 'mx-2 mb-4']">

      <!-- <span style="color: black;">{{ question }}</span> -->
 
      <div :style="forRedData.includes(question.survey_questionID)?'background-color:#ff000045; border-radius:10px':'background-color:transparent'" :class="[{ 'col-sm-12': question.answers.length > 3 }, { 'col-sm-6': question.answers.length <= 3 }, 'question-text px-2']">
        <p >{{ helpers.getLocalizedField(question, 'question') }}</p>
 
      </div>

      <div :class="[{ 'col-sm-12': question.answers.length > 3 }, { 'col-sm-6': question.answers.length <= 3 }, 'px-2']">
        <!-- checkboxes -->
         <div  v-if="question.checkbox == 1 && question.raiting==0" class="text-left">
         <div  v-for="answer of question.answers">
            <div class="form-check mb-2">
              <input class="form-check-input"
                     type="checkbox"
                     v-model="answerIds"
                     @change="handleCheck(question)"
                     :value="answer.survey_answerID"
                     :id="'question-'+question.survey_questionID+'-'+answer.survey_answerID">
              <label class="form-check-label" :for="'question-'+question.survey_questionID+'-'+answer.survey_answerID">
                {{ helpers.getLocalizedField(answer, 'answer') }}
              </label>
            </div>
         </div>
        </div>

        <!-- others -->

        

        <div  v-else :class="[{ 'justify-content-end': question.answers.length <= 3  &&  width > 993 && question.additional==0 }, 'ml-4 d-flex mt-2 mb-4 mb-sm-0']" >
         
          <div v-if="question.raiting==0" :class="[{ 'fb-75 d-flex justify-content-around': width > 993 && question.additional==0 }]">
            <div :class="[{'form-check form-check-inline':question.additional==0}, 'text-left']" v-for="answer of question.answers">
              <input class="form-check-input" type="radio"
                     @change="handleCheck(question)"
                     :id="'question-'+question.survey_questionID+'-'+answer.survey_answerID"
                     :value="answer.survey_answerID"
                     v-model="answerId">
              <label class="form-check-label text-left" :for="'question-'+question.survey_questionID+'-'+answer.survey_answerID">
                {{ helpers.getLocalizedField(answer, 'answer') }}
              </label>
              
            </div>  
            <!-- <div v-if="question.additional==1" class="d-flex">
              <input class="form-check-input mr-1" type="radio"
                     @change="handleCheck(question)"
                     :id="'question-'+question.survey_questionID+'-1111111111111111111111111111111111111111111111111111111111'"
                     value="1111111111111111111111111111111111111111111111111111111111"
                     v-model="answerId">
                 <textarea @keyup="getText" class="form-control"></textarea>
            </div> -->
             
          </div> 



          <div v-if="question.raiting==1"  >
            <div  v-for="answer of question.answers">
              <label class="form-check-label text-left w-100" :for="'question-'+question.survey_questionID+'-'+answer.survey_answerID">
                {{ helpers.getLocalizedField(answer, 'answer') }}
               
              <input min="1" :max="question.answers.length" class="form-control" style="width: 100px; border: 1px solid grey;" type="number"
                     @keyup="handleRating(question)"
                     :id="'question-'+question.survey_questionID+'-'+answer.survey_answerID" 
                     
                      
                     > 
                    </label>
              
            </div>  

             
          </div> 






        </div>
        
      </div>
     
    </div>
    
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'closed-question',

  props: ['question', 'answerData'],

  data() {
    return {
      answerId: '',
      answerIds: [],
      width:document.documentElement.clientWidth,
      ratingAnswers:[{answerID:null,rating:null}],
      answerText:null,
      forRedData:[]
      
    }
  },

  created() {
    if(this.answerData) {
      this.answerId = this.answerData.answerId;
      this.answerIds = this.answerData.answerId;
    }



    window.addEventListener('resize',()=>{

      this.width=document.documentElement.clientWidth

    })

  },

  watch:{

    forRed(val){

    

      if(val.length>0){
        
        this.forRedData=val

      }

    }

  },

  computed:{

    ...mapGetters({
        forRed:'survey/getForRed'
    })

  },
  

  methods: {


    // getText(e){

    //     console.log(e)

    // },

    handleRating(question){


      this.ratingAnswers=[]

        for(let answer of question.answers){

            if(document.getElementById('question-'+question.survey_questionID+'-'+answer.survey_answerID).value){

              if(document.getElementById('question-'+question.survey_questionID+'-'+answer.survey_answerID).value<=0 || document.getElementById('question-'+question.survey_questionID+'-'+answer.survey_answerID).value>question.answers.length){

                alert(this.$t('surveys.notAllowedValue'))
                document.getElementById('question-'+question.survey_questionID+'-'+answer.survey_answerID).value=null
                return false

              }else {


                  this.ratingAnswers.push({ 
                    answerID:answer.survey_answerID,
                    rating:document.getElementById('question-'+question.survey_questionID+'-'+answer.survey_answerID).value
                  })


              }


            }

        }
 
        this.$emit('answered', question.survey_questionID, this.ratingAnswers);
         

    },



    handleCheck(question) {
      if(question.checkbox == 1){
        this.$emit('answered', question.survey_questionID, this.answerIds);
      }
      else{
        this.$emit('answered', question.survey_questionID, this.answerId);
      }

     if(question.sur_answer_typeID==6){ 
            this.$emit('getAdditionalQuestionAnswer',question.survey_questionID,this.answerId) 

     }


    },
  }
};
</script>

<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}





p{
    color: #494949;
    font-size: 1.5rem;
    line-height: 2.6rem;
    margin-bottom: 13px;
}
.border-bottom,
.border-right{
  border-color: rgba(85, 124, 132, 0.3) !important;
}

.closed-question {
  border-bottom: 1px solid rgba(85, 124, 132, 0.3);
}

.fb-75{ flex-basis: 75%;}
@media screen and (max-width: 998px){
  .fb-75{
    flex-basis: 100%;
  }
}
.question-text{
  text-align: left;
}
input[type="checkbox"].form-check-input  {
margin-left: -2rem;
}
</style>
