<template>
  <div>
     
    <div class="subjects-container mb-4">
 
 

      <div v-for="question of theQuestions">

       
        <survey-questions  
          @answered="handleAnswer"
          @getAdditionalQuestionAnswer="getAdditionalQuestionAnswer"
          :answers="answers"
          :question="question"></survey-questions>
           <hr   v-if="theQuestions.filter(o=>o.item.survey_questionID==question.item.mainQuestionID).length>0">
      </div>
       
    </div>
    <div class="text-center"><button id="saveButton" @click="handleSave" class="btn btn-light-medium">{{ $t('surveys.submit') }}</button></div>
  </div>
</template>

<script>
import SurveyResource from '../../api/surveyResource';
import SurveyQuestions from './survey-questions';

const surveyResource = new SurveyResource();

export default {
  name: 'program-survey',
  components: { SurveyQuestions },
  props: ['survey'],

  data() {
    return {
      answers: [],
      mainAnswerID:null
      
    }
  },
 

  computed:{
    theQuestions(){

      return this.survey.questions.filter(o=>!o.item.mainQuestionID)

    }


  },

  methods: {

    getAdditionalQuestionAnswer(q,a){ 
        
      this.mainAnswerID=a
      if(this.theQuestions.filter((obj)=>{

            return obj.item.mainQuestionID==q

            }).length>0){ 

              for(let d of this.theQuestions.filter((obj)=>{

                return obj.item.mainQuestionID==q

            })){

 

            this.theQuestions.splice(this.theQuestions.findIndex((ob)=>{

              return ob.item.survey_questionID==d.item.survey_questionID
            }),1)


            } 


            }








        for(let data of      this.survey.questions.filter((o)=>{
          return o.item.mainQuestionID==q && o.item.mainAnswerID==a
        }).sort((a,b)=>b.item.changeable-a.item.changeable)){


          this.theQuestions.splice(
              Math.max(this.theQuestions.findIndex((ob)=>{


                  return ob.item.survey_questionID==data.item.mainQuestionID

              }))+1
          
          
          ,0,data)
           
           

        }
 
        

       
    },


    handleSave() { 


     

      let answerArray=[]

      let arrayForRed=[]

      for(let ans of this.answers){

        answerArray.push(ans.questionId)

      }

      

        for(let data of this.theQuestions){


          if(data.item.isVisible==1 && data.item.required==1){


              if(!answerArray.includes(data.item.survey_questionID)){

                      arrayForRed.push(data.item.survey_questionID)

                      

                      
              }
          }


        }

      
          if(arrayForRed.length>0){

            this.$store.dispatch('survey/setForRed',arrayForRed)
            return

          }
 

 
        document.getElementById('saveButton').style.display='none'



      for(let answer of this.answers){


          let x=this.theQuestions.find((ob)=>{

            return ob.item.survey_questionID==answer.questionId && ob.item.raiting==1

          })

          if(x){

            if(x.item.answers.length!=answer.answerId.length){

              alert(this.$t('surveys.pleaseFill'))
              return
            }

          }


      }


     


      
      surveyResource.save(this.survey.surveyID, this.answers)
      .then(response => {
        this.helpers.notifySuccessMessage(this.$t('surveys.success'));
        this.$emit('submitted', this.survey);
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error);
        document.getElementById('saveButton').style.display='inline'
      });
    },

    handleAnswer(answerData) {
      let alreadyAnswered = Object.assign([], this.answers)
        .find(o => o.questionId === answerData.questionId);

      if(alreadyAnswered) this.answers.splice(this.answers.indexOf(alreadyAnswered), 1);

      answerData.mainAnswerID=this.mainAnswerID

      this.answers.push(answerData);
    },
  }
};
</script>

<style lang="scss" scoped>

hr{
  border-bottom: 1px solid #72afba !important;
}
.subjects-container{
  background-color: rgba(255,255,255,.95);
  border-radius: 30px;
  overflow: hidden;
  padding: 20px;
}
.subject{
  background-color: rgba(255,255,255,.95);
  border-radius:30px;
  margin-bottom: 1.5rem;
  color: #557c84;
  padding: 1rem 1.95rem ;
  text-align: left;
  font-size: 1.75rem;
  line-height: 25px;
  box-shadow: 0 1px 11px rgba(148, 148, 148, .16);
  &-title{
      padding: 1rem 1.5rem;
      cursor: pointer;
      transition: all .2s;
      border-radius:15px;
  }
  &:hover, &.active{
    .subject-title{
      background-color: rgba(85, 124, 132, .25);
    }
  }
}

</style>
