import NotificationResource from '../api/notificationResource';

const notificationResource = new NotificationResource();

export default {
  namespaced: true,

  state: {
    notifications: {
      loading: false,
      page: 1,
      perPage: 20,
      data: []
    },
    counts: {
      new: null,
      messages: null,
    }
  },

  getters: {
    notifications: state => state.notifications.data,
    loading: state => state.notifications.loading,
    newMessages: state => state.counts.messages,
    newNotifications: state => state.counts.new,
  },

  actions: {
    seen({ state }, notificationID) {
      return new Promise((resolve, reject) => {
        notificationResource.seen(notificationID).then((response) => {
          resolve(response);
        })
          .catch(error => {
            reject(error);
          });
      });
    },

    seeAll() {
      return new Promise((resolve, reject) => {
        notificationResource.seeAll().then((response) => {
          resolve(response);
        })
          .catch(error => {
            reject(error);
          });
      });
    },

    loadCounts({ state }) {
      return new Promise((resolve, reject) => {
        notificationResource.counts().then((response) => {
          state.counts = response.data.data;
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
      });
    },

    loadNotifications({ state }, data) {
      const page = data && data.page ? data.page : state.notifications.page;
      const perPage = data && data.perPage ? data.perPage : state.notifications.perPage;

      state.notifications.loading = true;
      return new Promise((resolve, reject) => {
        notificationResource.notifications(page, perPage).then((response) => {
            state.notifications = {
              loading: false,
              data: response.data.data,
              page: page,
              perPage: perPage
            };
            resolve(response);
          })
          .catch(error => {
            state.notifications.loading = false;
            reject(error);
          });
      });
    },
  },
};
