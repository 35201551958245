import axios from 'axios';

export default class FinancialInfoResource {
  generalData() {
    return axios.get('/api/financial-info/general');
  }

  finance_temp(){


    return axios.get('api/financial-info/finance_temp');
  }

  financial_info_by_kikabidze(){


    return axios.get('api/financial-info/financial_info_by_kikabidze');
  }


   

  details() {
    return axios.get('/api/financial-info/details');
  }

  getScholarship() {
    return axios.get('/api/financial-info/getScholarship');
  }

  saveBankAccount(bankAccount){

    return axios.post('/api/financial-info/saveBankAccount',{bankAccount});
  }

   

  debitTypes () {
    return axios.get('/api/financial-info/debit-types');
  }

  getPaymentGatewayLink (amount, debitType, payForId) {
    return axios.post('/api/financial-info/pay', {
      amount: amount,
      payment_type: debitType,
      pay_for_id: payForId
    })
  }


  saveInformaticsInfo(phoneNumber,address){

    return axios.post('/api/financial-info/saveInformaticsInfo',{phoneNumber,address});


  }


}
