<template>
    <div>
      <b-modal
        :hide-header="true"
        :hide-footer="true"
        content-class="modal-blue"
        id="has-bank-account-modal"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        :hide-header-close="true"
        size="lg">
        <!-- Modal content -->
        <h3 class="text-center">{{ $t('financial-info.scholarShipInfo') }}</h3>
        <div class="row text-white justify-content-center">
            <div class="col-6">
                

                <form>
                    <div class="mb-2">
                        <input class="form-control" type="text" v-model="accountNumber" :placeholder="$t('financial-info.bank_account')"/>
                    </div>
                    <div class="mb-2">
                    <button v-if="accountNumber" type="button" @click="saveBankAccount()" :disabled="accountNumber.length!=22" class="btn btn-secondary">{{ $t('financial-info.save') }}</button>
                     </div>   
                </form>
  
  </div>
        </div>
      </b-modal>
  
    </div>
  </template>
  
  <script>

import FinancialInfoResource from '../api/financialInfoResource'; 
const financialInfoResource = new FinancialInfoResource();
   export default{
        data(){
            return {

                accountNumber:null

            }
            
        },
        methods:{

            saveBankAccount(){

                financialInfoResource.saveBankAccount(this.accountNumber).then(()=>{
                 location.reload()
                })

            }

        }

   }
  
  
  </script>
  
   
   