import axios from 'axios';

export default {
  namespaced: true,

  state: {
    semesters: [],
  },

  getters: {
    semesters: state => state.semesters,
    subjects: state => state.semesters && state.semesters[0] && state.semesters[0].subjects ? state.semesters[0].subjects : []
  },

  mutations: {
    loadSemesters(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.semesters = data;
    },
    loadSubjects(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.semesters[data.semesterIdx].subjects = data.subjects;
    },
    loadEvaluations(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.semesters[data.semesterIdx].subjects[data.subjectIdx].evaluations = data.evaluations;
    },
  },

  actions: {
    loadSemesters({ commit, state, rootGetters }) {
      return new Promise((resolve) => {
        axios.get('/api/evaluations/semesters')
          .then((resp) => {
            commit('loadSemesters', resp.data.data);
              resolve(resp);
          });
      });
    },
    loadSubjects({ commit, state }, semesterIdx) {
      return new Promise((resolve) => {
        axios.get('/api/evaluations/' + state.semesters[semesterIdx].semesterID)
          .then((resp) => {
            commit('loadSubjects', {
              semesterIdx,
              subjects: resp.data.data
            });
            resolve(resp);
          });
      });
    },

    loadGeneralData() {
      return new Promise((resolve) => {
        axios.get('/api/evaluations/general-data')
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadEvaluations({ commit, state }, { semesterIdx, subjectIdx, registrationID }) {
      return new Promise((resolve) => {
        axios.get('/api/evaluations/' + state.semesters[semesterIdx].semesterID + '/' + state.semesters[semesterIdx].subjects[subjectIdx].subjectID + '/' + registrationID)
          .then((resp) => {
            commit('loadEvaluations', {
              semesterIdx,
              subjectIdx,
              evaluations: resp.data.data
            });
            resolve(resp);
          });
      });
    },
  },
};
