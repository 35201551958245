<template>
    <div>
      <b-modal
        :hide-header="true"
        :hide-footer="true"
        content-class="modal-blue"
        id="confirmation-self-gov-modal"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        :hide-header-close="true"
        size="lg">
        <!-- Modal content -->
        <h3 class="text-center">{{ $t('clubs.confirmationText') }}</h3>
        <div class="row text-white justify-content-center">
            <div class="col-6" v-if="confirmSelfGov.confirmSelfGov">
                
                    {{ confirmSelfGov.confirmSelfGov.club_name }}


                    <div class="row justify-content-around">
                      <div class="col-4">
                    <button  type="button" @click="handleAnswer(1)"   class="btn btn-secondary">{{ $t('clubs.yes') }}</button>
                    </div>
                    <div  class="col-4">
                         <button  type="button" @click="handleAnswer(0)"   class="btn btn-secondary">{{ $t('clubs.no') }}</button>
                      </div>
                     </div>  
  
            </div>
        </div>
      </b-modal>
  
    </div>
  </template>
  
  <script>

import {mapGetters} from  'vuex'
 
import ClubResource from '../../api/clubResource';

const clubResource=new ClubResource()
 
   export default{
        
       
        computed:{
          ...mapGetters({
            confirmSelfGov:'auth/user'
          })
        },

        methods:{

          handleAnswer(answer){

            clubResource.handleAnswer(this.confirmSelfGov.confirmSelfGov.self_governmentID,answer).then(()=>{
              location.reload()
            })


          }

        }
     

   }
  
  
  </script>
  
   
   