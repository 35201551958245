<template>
  <div>
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="contract-modal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="xl">
      <!-- Modal content -->
      <div class="row text-white content">
              
              <div class="col-12" v-for="(contract,i) in contracts" :key="i">

                            <div v-html="contract.messagetext"></div>
                   
                            <button class="btn btn-secondary btn-block" @click="saveAnswer(i)">AGREE</button>
              
              </div>


      </div>
    </b-modal>

  </div>
</template>

<script>
 

export default {

        data(){

            return {

                    contracts:[] 


            }


        },



    mounted(){

            this.$store.dispatch('auth/getContracts').then(response=>{

                       this.contracts=response.data


            })



    },



            methods:{

               
                saveAnswer(messageID){

                          

                        this.$store.dispatch('auth/saveContractAnswer',{messageID}).then(response=>{


                                window.location.href='/'

                        })

                }

                    

            }


   
};
</script>

 