import axios from 'axios';

export default {
  namespaced: true,

  state: {
    generalInfo: {},
    programModuleSubjects: [],
    programEvaluations: []
  },

  getters: {
    generalInfo: state => state.generalInfo,
    programModuleSubjects: state => state.programModuleSubjects,
    programEvaluations: state => state.programEvaluations,
  },

  mutations: {
    loadGeneralInfo(state, data) {

      
      // eslint-disable-next-line no-param-reassign
      state.generalInfo = data;

 
    },
    loadEvaluation(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.programEvaluations = data;
    },
    loadProgramModuleSubjects(state, data) {

 
      const programModule = state.generalInfo.modules.filter(o => parseInt(o.program_moduleID) === parseInt(data.moduleID));
 
 
      
      if (programModule)
        programModule.subjects = data.subjects;

      if(state.programModuleSubjects.filter(o => {
        return !o.subjects || o.subjects.length === 0
      }).length === 0) {
        state.programModuleSubjects.push(programModule);

       

      }
    },
  },

  actions: {
    loadProgramEvaluations({ commit }) {
      return new Promise((resolve) => {
        axios.get('/api/programs/my-evaluations')
          .then((resp) => {
            commit('loadEvaluation', resp.data.data);
            resolve(resp);
          });
      });
    },

    loadGeneralInfo({ commit }, data) {

    
      return new Promise((resolve) => {
        axios.get('/api/programs/' + data.programID + '/general-info')
          .then((resp) => {
           


            
            commit('loadGeneralInfo', resp.data.data);
            resolve(resp);
          });
      });
    },

    loadProgramModuleSubjects({ commit }, data) {
      let url = '/api/programs/' + data.programID + '/module/' + data.moduleID + '/subjects'

  

      if (data.concentrationID) {
        url += '/' + data.concentrationID
      }

      return new Promise((resolve) => {
        axios.get(url)
          .then((resp) => {

            
            if (data.concentrationID) {
              commit('loadProgramModuleSubjects', {
                moduleID: data.moduleID,
                concentrationID: data.concentrationID,
                subjects: resp.data.data,
                concentration:data.concentration
              });
            }else {



            commit('loadProgramModuleSubjects', {
              moduleID: data.moduleID,
              concentrationID: data.concentrationID,
              subjects: resp.data.data,
              concentration:''
            });
          }
            resolve(resp);
          });
      });
    },

    getFullProgram(){

      return axios.get('/api/programs/getFullProgram')


    }
  },
};
