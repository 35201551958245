import axios from 'axios';

export default class SurveyResource {
  surveys() {
    return axios.get('/api/surveys');
  }

  subjects(surveyId) {
    return axios.get(`/api/surveys/subjects/${surveyId}`);
  }

  lecturers(surveyId, subjectId) {
    if(subjectId) return axios.get(`/api/surveys/lecturers/${surveyId}?subject_id=${subjectId}`);

    return axios.get(`/api/surveys/lecturers/${surveyId}`);
  }

  save(surveyId, answers) {
    return axios.post(`/api/surveys/${surveyId}`, {
      answers
    });
  }
}
