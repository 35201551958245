import axios from 'axios';

export default {
  namespaced: true,

  state: {
    exams: [],
    maxExams: [],
    examTypes: [],
 
  },

  getters: {
    exams: state => state.exams,
    maxExams: state => state.maxExams,
    examTypes: state => state.examTypes,
  
  },

  mutations: {
    loadExams(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.exams = data;
    },
    loadMaxExams(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.maxExams = data;
    },
    loadExamTypes(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.examTypes = data;
    },
   
  },

  actions: {
    loadExamComment(maxExamsScheduleID) {
      return new Promise((resolve) => {
        axios.get('/api/view-exams/comments/'+maxExamsScheduleID)
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadAnswers(unitID) {
      return new Promise((resolve) => {
        axios.get('/api/view-exams/answers/'+unitID)
          .then((resp) => {
            resolve(resp);
          });
      });
    },

 

    loadAnswerBlanks(unitID) {
      return new Promise((resolve) => {
        axios.get('/api/view-exams/answer-blanks/'+unitID)
          .then((resp) => {
            resolve(resp);
          });
      });
    },

    loadExams({ commit }) {
      return new Promise((resolve) => {
        axios.get('/api/exam-schedule')
          .then((resp) => {
            commit('loadExams', resp.data.data);
            resolve(resp);
          });
      });
    },
    loadMaxExams({ commit }) {
      return new Promise((resolve) => {
        axios.get('/api/view-exams')
          .then((resp) => {
            commit('loadMaxExams', resp.data.data);
            resolve(resp);
          });
      });
    },
    loadExamTypes({ commit }) {
      return new Promise((resolve) => {
        axios.get('/api/exam-schedule/types')
          .then((resp) => {
            commit('loadExamTypes', resp.data.data);
            resolve(resp);
          });
      });
    },
   


    getInstructions(state){  
      return axios.get('/api/exam-schedule/examInstructions'); 
    }


  },
};
