<template>
    <b-modal
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="lg"
      content-class="modal-blue"
      v-model="isOpen"
      id="choose-concentration">
      <div class="row">
        <div class="col mb-5 pb-5">
          <div class="smy-4 mx-5 font-16 text-left">
            <div>
              {{ concentrations[0].popup_message }}
            </div>
            <div class="mt-5">
              <b-form-group :label="concentrations[0].speciality">
                <b-form-radio :key="i" v-for="(concentration, i) of concentrations" v-model="concentrationID" :value="concentration.concentrationID">
                  {{ concentration.concentration }}
                </b-form-radio>
              </b-form-group>
            </div>
         </div>

        </div>
      </div>
      <div class="row">
        <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
          <button type="button" class="btn btn-light-bold" @click="onSubmitClick">შენახვა</button>
        </div>
      </div>
    </b-modal>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  name: 'choose-concentration',

  computed: {
    ...mapGetters({
      concentrations: 'auth/concentrationData'
    }),
  },

  data() {
    return {
      isOpen: true,
      concentrationID: '',
    }
  },

  methods: {
    showModal() {
      this.$bvModal.show('upload-docs-modal');
    },

    onSubmitClick() {
      axios.post('/api/choose-concentration', {
        concentration_id: this.concentrationID
      })
      .then((response) => {
        window.location.reload();
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error)
      })
    }
  }
};
</script>

<style>
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  .font-16 {
    font-size: 16px !important;
  }

</style>
