<template>
  <div>
    <b-modal
      :hide-header="chosenSurvey && chosenSurvey.lock==1?true:false"
      :hide-footer="true"
      content-class="modal-blue"
      id="survey-modal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="chosenSurvey && chosenSurvey.lock==1?true:false"
      size="xl">
      <!-- Modal content -->
      <div class="row text-white content">
        <div v-if="chosenSurvey" class="col mb-5 pb-5">
          <div class="mb-4">{{ $t('surveys.title') }}</div>
 


          <div class="mb-5 text-left" v-html="helpers.getLocalizedField(chosenSurvey, 'survey_description')"></div>

          <subject-lecturer-survey v-if="chosenSurvey.type === 'საგანი/ლექტორი'"
                                   :survey="chosenSurvey"
                                    @submitted="handleSubmit"></subject-lecturer-survey>
          <program-survey v-if="chosenSurvey.type === 'თავისუფალი გამოკითხვა'"
                          :survey="chosenSurvey"
                          @submitted="handleSubmit"></program-survey>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubjectLecturerSurvey from './subject-lecturer-survey';
import ProgramSurvey from './program-survey';

export default {
  name: 'survey-modal',
  components: { SubjectLecturerSurvey, ProgramSurvey },
  computed: {
    ...mapGetters({
      surveys: 'survey/surveys'
    })
  },

  watch: {
    surveys(val) {
      if(val.length > 0) {
        this.setChosenSurvey(val[0]);
      } else {
        this.$bvModal.hide('survey-modal');
        this.chosenSurvey = null;
      }
    }
  },

  data() {
    return {
      chosenSurvey: null,
      surveyData: [],
    }
  },

  methods: {
    setChosenSurvey(survey) {
      this.chosenSurvey = survey;
    },

    handleSubmit(survey) {
      this.$store.dispatch('survey/unsetSurvey', survey);
    }
  }
};
</script>
<style scoped>
  .content {
    font-size: 1.6rem;
  }
</style>
