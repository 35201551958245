import axios from 'axios';

export default class ScheduleResource {
  resource(subjectID) {
    return axios.get('/api/resources/'+subjectID);
  }

  currentSchedule() {
    return axios.get('/api/current-schedule');
  }

  dateTimes(lectureTypeID) {
    const param = lectureTypeID ? '/' + lectureTypeID : '';

    return axios.get('/api/date-times' + param);
  }
}
