import axios from 'axios';

export default class NotificationResource {
  notifications(page, perPage) {
    return axios.get('api/notifications?page=' + page + '&per_page=' + perPage);
  }

  counts() {
    return axios.get('api/notifications/new');
  }

  seen(notificationID) {
    return axios.get('api/notifications/' + notificationID);
  }

  seeAll() {
    return axios.post('api/notifications');
  }
}
