import axios from 'axios';
import helpers from '../helpers';

export default {
  namespaced: true,

  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    studentPicture: '',
    mustChoosePractise: false,
    mustChooseSeminar: false,
    mustUploadDocs: false,
    mustChooseSubjects: false,
    hasGraduation:false,
    comeToGraduation:false,
    concentrationData: [],
    contractModal:false,
    concentration:0,
    studentStatus:null,
    studentStatuseng:null,
    email_UG:null,
    hasBankAccount:null,
    informatics:false,
    confirmSelfGov:null,
    missedDocuments:null,
    currentExam:0
  },

  getters: {
    isLoggedIn: state => !!state.token,
    mustChoosePractise: state => state.user.must_choose_practise,
    mustChooseSeminar: state => state.mustChooseSeminar,
    mustUploadDocs: state => state.mustUploadDocs,
    mustUploadVaccineDocs: state => state.user.must_upload_vaccine_docs,
    mustUploadVaccineMessage: state => state.user.must_upload_vaccine_message,
    mustChooseSubjects: state => state.mustChooseSubjects,
    hasGraduation:state=>state.hasGraduation,
    comeToGraduation:state=>state.comeToGraduation,
    contractModal:state=>state.contractModal,
    concentrationData: state => state.concentrationData,
    authStatus: state => state.status,
    authenticated: state => state.status === 'success',
    token: state => state.token,
    concentration: state=>state.concentration,
    user(state) {
      return {
        studentID: state.user.studentID,
        code: state.user.code,
        firstName: state.user.fname,
        lastName: state.user.lname,
        personalId: state.user.personal_code,
        fullName: `${state.user.fname} ${state.user.lname}`,
        fullNameEng: `${state.user.fnameeng} ${state.user.lnameeng}`,
        schoolID: state.user.schoolID,
        school: state.user.school,
        schoolEng: state.user.schooleng,
        speciality: state.user.speciality,
        specialityEng: state.user.specialityeng,
        programID: state.user.programID,
        minorProgramID: state.user.minor_programID,
        ects: parseInt(state.user.ects),
        studyLevel: parseInt(state.user.study_levelID),
        programCategoryID: parseInt(state.user.program_categoryID),
        currency: state.user.currency,
        must_choose_english: state.user.must_choose_english,
        is_on_last_course: state.user.is_on_last_course,
        concentration: state.user.concentration,
        studentStatus: state.user.studentStatus,
        studentStatuseng: state.user.studentStatuseng,
        email_UG:state.user.email_UG,
        hasBankAccount:state.user.hasBankAccount,
        informatics:state.user.informatics,
        confirmSelfGov:state.user.confirmSelfGov,
        missedDocuments:state.user.missedDocuments,
        currentExam:state.user.currentExam,
         
   
       
      };
    },
    studentPicture: state => state.studentPicture,
  },

  mutations: {
    auth_request(state) {
      // eslint-disable-next-line no-param-reassign
      state.status = 'loading';
    },
    auth_success(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.status = 'success';
      // eslint-disable-next-line no-param-reassign
      if (data.token) state.token = data.token;
      // eslint-disable-next-line no-param-reassign
      state.user = data.user;
        
      state.mustChooseSubjects = data.user.must_choose_subjects
      state.hasGraduation=  data.user.has_graduation
      state.contractModal=data.user.contractModal
      state.comeToGraduation=  data.user.come_to_graduation
      state.concentration=data.user.concentration
      state.studentStatus=data.user.studentStatus
      state.studentStatuseng=data.user.studentStatuseng
      state.email_UG=data.user.email_UG
      state.hasBankAccount=data.user.hasBankAccount
      state.informatics=data.user.informatics
      state.confirmSelfGov=data.user.confirmSelfGov,
      state.missedDocuments=data.user.missedDocuments,
      state.currentExam=data.user.currentExam,
      
      // axios.get('/api/registration/must-choose-seminar/'+state.user.studentID)
      //   .then((resp) => {
      //     if(resp.data.message == '1') state.mustChooseSeminar = true;
      //   });
      //
      // axios.get('/api/choose-concentration')
      //   .then((resp) => {
      //     state.concentrationData = resp.data.data;
      //   });

      // if(state.user.program_categoryID == '2' && state.user.currency !== 'GEL') {
      //   axios.get('/api/must-upload-docs')
      //     .then((resp) => {
      //       if(resp.data.message == '1') state.mustUploadDocs = true;
      //     });
      // }

      helpers.getStudentPicture().then((response) => {
        // eslint-disable-next-line no-param-reassign
        state.studentPicture = `data:image/jpeg;base64,${response.data}`;
      });
    },
    auth_error(state) {
      // eslint-disable-next-line no-param-reassign
      state.status = 'error';
    },
    logout(state) {
      // eslint-disable-next-line no-param-reassign
      state.status = '';
      // eslint-disable-next-line no-param-reassign
      state.token = '';
      state.user = {};
      state.studentPicture = '';
    },
  },

  actions: {
    setMustChooseSubjects({ state }, value) {
      state.setMustChooseSubjects = value
    },

    setConcentration({state},value){

        state.concentration=value

    },

    setstudentStatus({state},value){

      state.studentStatus=value

  },

  studentStatuseng({state},value){

    state.studentStatuseng=value

},

email_UG({state},value){

  state.email_UG=value

},

hasBankAccount({state},value){
  state.hasBankAccount=value
},

informatics({state},value){
  state.informatics=value
},

 


currentExam({state},value){
  state.currentExam=value
},



 

missedDocuments({state},value){

  state.missedDocuments=value
},

confirmSelfGov({state},value){

  state.confirmSelfGov=value

},
   



     

    setHasGraduation({state},value){

        state.setHasGraduation=value

    },
    setComeToGraduation({state},value){

      state.setComeToGraduation=value

  },

    setContractModal({state},value){

        state.setContractModal=value

    },
    
    updateMustChooseSeminar(state, value) {
      state.mustChooseSeminar = value;
    },
    user({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios('/api/auth/user')
          .then((resp) => {
            // eslint-disable-next-line prefer-destructuring
            const user = resp.data.data.user;

           

            commit('auth_success', { toke: null, user });
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },

    loginWithToken({ commit }, { user_id, password }) {
      axios({ url: '/api/auth/login-with-token', data: { user_id, password }, method: 'POST' })
        .then((resp) => {
          // eslint-disable-next-line prefer-destructuring
          const token = resp.data.data.token;
          // eslint-disable-next-line no-shadow,prefer-destructuring
          const user = resp.data.data.user;

          localStorage.setItem('token', token);
          axios.defaults.headers.common.Authorization = token;
          commit('auth_success', { token, user });

          window.location.href = '/';
        })
        .catch((err) => {
          commit('auth_error');
        });
    },

    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({ url: '/api/auth/login', data: credentials, method: 'POST' })
          .then((resp) => {
            // eslint-disable-next-line prefer-destructuring
            const token = resp.data.data.token;
            // eslint-disable-next-line no-shadow,prefer-destructuring
            const user = resp.data.data.user;

            localStorage.setItem('token', token);
            axios.defaults.headers.common.Authorization = token;
            commit('auth_success', { token, user });
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },

    resetPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/api/auth/reset-password', data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common.Authorization;
        window.location.href = '/login';
        resolve();
      });
    },


      graduationAnswerSave(state,{chosenType}){

        return axios.post('/api/saveGraduationAnswer',{chosenType})

      },
      graduationComeAnswerSave(state,{answer}){

        return axios.post('/api/saveGraduationComeAnswer',{answer})

      },

      getContracts(state){

          return axios.post('/api/foreignContracts/getContract')


      },

      saveContractAnswer(state,{messageID}){

        return axios.post('/api/foreignContracts/saveContractAnswer',{messageID})

      }




    



  },
};
