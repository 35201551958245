<template>
  <div class="container-fluid">
    <div class="row">
      <router-view/>
      <!-- <practise-groups-modal
        :disabled=true
        v-if="mustChoosePractise"
        :subject="practiseData.subject"
        :seminar="practiseData.seminarGroup"
        :group="practiseData.group"
        :already-registered="practiseData.alreadyRegistered"
        @register-practise="choosePractise"
      ></practise-groups-modal> -->
<!--      <seminar-groups-modal-->
<!--        v-if="mustChooseSeminar"-->
<!--        @register-seminar="chooseSeminar"-->
<!--        :subject="seminarData.subject"-->
<!--        :group="seminarData.group"-->
<!--        :already-registered="seminarData.alreadyRegistered"></seminar-groups-modal>-->
<!--      <choose-concentration v-if="concentrationData.length > 0"></choose-concentration>-->
<!--      <upload-vaccine-docs-modal v-if="mustUploadVaccineDocs"></upload-vaccine-docs-modal>-->
      <survey-modal></survey-modal>
      <!-- <survey-law-subjects-modal></survey-law-subjects-modal> -->

      <graduation ></graduation>
      <graduation-come></graduation-come>

      <!-- <contract-modal></contract-modal> -->

      <has-bank-account></has-bank-account>
      <informatics></informatics>
      
      <confirmation-modal></confirmation-modal>

<!--      <student-location-modal v-if="user && user.is_in_georgia === null && user.currency !== 'GEL'"></student-location-modal>-->
    </div>
  </div>
</template>

<script>
import Login from './pages/login';
import { mapGetters } from 'vuex';
import axios from 'axios';
import PractiseGroupsModal from '../src/components/registration/practise-groups-modal';
import SeminarGroupsModal from '../src/components/registration/seminar-groups-modal';
import SurveyResource from './api/surveyResource';
import SurveyModal from './components/survey/survey-modal';
import StudentLocationModal from './components/student-location-modal';
import ChooseConcentration from './components/home/choose-concentration';
import UploadVaccineDocsModal from './components/home/upload-vaccine-docs-modal';
import SurveyLawSubjectsModal from './components/survey-law-subjects-modal';
import graduation from './components/graduation/graduation';
import graduationCome from './components/graduation/graduationCome';
import contractModal from './components/foreignStudentsContractPopUp/contract-modal';
import hasBankAccount from './components/hasBankAccount';
import informatics from './components/informatics';
import confirmationModal from './components/clubs/confirmationModal'

const surveyResource = new SurveyResource();

export default {
  name: 'app',
  components: {
    SurveyLawSubjectsModal,
    UploadVaccineDocsModal,
    ChooseConcentration,
    PractiseGroupsModal,
    SeminarGroupsModal,
    SurveyModal,
    Login,
    StudentLocationModal,
    graduation,
    graduationCome,
    contractModal,
    hasBankAccount,
    informatics,
    confirmationModal
  },
  created() {

    if (typeof window.__gCrWeb !== 'undefined' &&
        window.__gCrWeb.languageDetection &&
        window.__gCrWeb.annotations) {
      window.__gCrWeb.languageDetection.detectLanguage();
      window.__gCrWeb.annotations.removeHighlight();
    } else {
      console.warn('__gCrWeb is not available.');
    }



    if (this.helpers.getCookie('userId') !== '' && this.helpers.getCookie('password') !== '') {
      localStorage.removeItem('token');

      this.loginWithToken();
    }

    this.$http.interceptors.response.use(undefined, err => new Promise(() => {
      // eslint-disable-next-line no-underscore-dangle
      if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        //this.$store.dispatch('auth/logout');
      }
      throw err;
    }));

    this.checkLogin();

    // const locale = localStorage.getItem('locale');
    // if(locale) this.$store.dispatch('language/setLocale', locale);
  },
 
  watch: {
    $route (to, from){
      if(this.mustUploadDocs) {
        this.$router.push('/');
      }

      if(this.mustChooseSubjects && to.name!=='logout') {
        
        this.$router.push('/pre-registration');
      }

          
    },

    // mustChoosePractise(mustChoose) {
    //   this.loadPractiseDataAndPopup();
    // },
    //
    // mustChooseSeminar(mustChoose) {
    //   this.loadSeminarDataAndPopup();
    // }

    

  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      // mustChoosePractise: 'auth/mustChoosePractise',
      mustChooseSeminar: 'auth/mustChooseSeminar',
      mustUploadDocs: 'auth/mustUploadDocs',
      mustUploadVaccineDocs: 'auth/mustUploadVaccineDocs',
      mustChooseSubjects: 'auth/mustChooseSubjects',
      concentrationData: 'auth/concentrationData',
      surveys: 'survey/surveys',
      user: 'auth/user',
      hasGraduation:'auth/hasGraduation',
      comeToGraduation:'auth/comeToGraduation',
      contractModal:'auth/contractModal',
      hasBankAccount:'auth/user',
      confirmSelfGov:'auth/user',
      informatics:'auth/user',

    })
  },

  data() {
    return {
      practiseData: {
        group: null,
        seminarGroup: null,
        subject: null,
        alreadyRegistered: null,
      },
      seminarData: {
        group: null,
        subject: null,
        alreadyRegistered: null,
      },
    }
    
  },

  methods: {
    choosePractise(group) {
      this.$store.dispatch('registration/registerPractise', {
        group: this.practiseData.group,
        practiseGroup: group.group
      })
        .then(response => {
          this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_registered'));
          this.checkLogin();
          this.$bvModal.hide('practise-groups-modal');
        }).catch(error => {
        this.helpers.notifyErrorMessage(error);
      });
    },

    chooseSeminar(group) {
      this.$store.dispatch('registration/registerSeminar', {
        group: this.seminarData.group,
        seminarGroup: group.group
      })
        .then(response => {
          this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_registered'));
          this.checkLogin();
          this.$bvModal.hide('seminar-groups-modal');
        }).catch(error => {
        this.helpers.notifyErrorMessage(error);
      });
    },

    showPractisePopup() {
      this.$bvModal.show('practise-groups-modal');
    },

    showSeminarPopup() {
      this.$bvModal.show('seminar-groups-modal');
    },

    showSurveyPopup() {
      this.$bvModal.show('survey-modal');
    },

    loadPractiseDataAndPopup() {
      this.$store.dispatch('registration/loadAlreadyRegistered')
      .then(response => {
        this.practiseData.alreadyRegistered = response.data.data.subjects;
      });

      axios.get('/api/registration/choose-practise')
      .then(response => {
        const data = response.data.data;
        this.practiseData.group = data.group;
        this.practiseData.seminarGroup = data.seminar_group;
        this.practiseData.subject = data.subject;
        this.showPractisePopup();
      });
    },

    loadSeminarDataAndPopup() {
      this.$store.dispatch('registration/loadAlreadyRegistered')
      .then(response => {
        this.seminarData.alreadyRegistered = response.data.data.subjects;
      });

      axios.get('/api/registration/choose-seminar')
      .then(response => {
        const data = response.data.data;
        this.seminarData.group = data.group;
        this.seminarData.subject = data.subject;
        this.showSeminarPopup();
      });
    },

    checkLogin() {
      const token = localStorage.getItem('token');
 
      if (token) {
        this.$store.dispatch('auth/user').then(() => {
          this.$store.dispatch('navbar/loadSidebarNavItems');
          this.$store.dispatch('navbar/loadHeaderNavItems');

          this.$store.dispatch('notifications/loadCounts');
          this.$store.dispatch('conversation/loadCounts');

          surveyResource.surveys()
          .then(response => {
            this.$store.dispatch('survey/setSurveys', response.data.data);
            if(response.data.data.length > 0) this.showSurveyPopup();
          })

          if(this.mustUploadDocs) {
            this.$router.push('/');
          }

          if(this.mustChooseSubjects) {
            this.$router.push('/pre-registration');
          }

          if(this.hasGraduation){

             this.$bvModal.show('graduation-modal'); 

          }

           if(this.comeToGraduation){

             this.$bvModal.show('graduation-come-modal'); 

          }
          
         
          if(this.hasBankAccount.hasBankAccount){
              
             
            this.$bvModal.show('has-bank-account-modal'); 
            

          }
 
          if(this.informatics.informatics){

            this.$bvModal.show('informatics'); 

          }

         
          if(this.confirmSelfGov.confirmSelfGov){

            
 
            this.$bvModal.show('confirmation-self-gov-modal'); 


            }

           
 

          if(this.contractModal){

              this.$bvModal.show('contract-modal')

          }


          // if (this.user.studentID === 19814) {
          //   (function(h,o,t,j,a,r){
          //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          //     h._hjSettings={hjid:2858240,hjsv:6};
          //     a=o.getElementsByTagName('head')[0];
          //     r=o.createElement('script');r.async=1;
          //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          //     a.appendChild(r);
          //   })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          // }


          /*this.$store.dispatch('registration/loadMustChangeGroups')
          .then(response => {
            if(response.data.data.length > 0) {
              this.$router.push('/registration');
            }
          });*/
        })
        .catch(err => {
          this.$router.push('/login');
        });
      }
    },

    loginWithToken() {
      this.$store.dispatch('auth/loginWithToken', {
        user_id: this.helpers.getCookie('userId'),
        password: this.helpers.getCookie('password'),
      })
      .then(() => {
        this.helpers.deleteCookie('userId');
        this.helpers.deleteCookie('password');
      })
      .catch(() => {
        this.$router.push('/');
      });
    },

    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push('/login');
        });
    },
  },
};
</script>

<style lang="scss" src="./assets/scss/main.scss"></style>
