<template>
  <div class="w-100">
    <section class="authorization-section">
      <div class="row no-gutters">
        <div class="col-11 col-sm-10 col-md-10 col-lg-9 col-xl-8 ml-auto">
          <div class="rectangle-shape">
            <div class="h-100 ">
              <div class="text-right order-2 order-sm-1">
                <div class="logo">
                  <img src="/static/images/logo-white.png" alt="ug-logo" class="img-fluid" />
                </div>
              </div>
              <div class=" d-flex flex-column justify-content-center align-items-center  text-center order-1 order-sm-2">
                <p class="f-3">{{ $t('login.title') }}</p>
<!--                <p class="f-2">Sign in to your account</p>-->

                <!-- Authorization Form start-->
                <form @submit.prevent="login">
                  <div class="input-group">
                    <input v-model="form.code" type="text" class="form-control form-control-lg px-5 rounded-pill" :placeholder="$t('login.code')" autofocus="1">
                    <img src="/static/images/icons/profile_logo.png" class="img-fluid ">
                  </div>
                  <div class="input-group">
                    <input v-model="form.password" type="password" class="form-control form-control-lg px-5 rounded-pill" :placeholder="$t('login.password')">
                    <img src="/static/images/icons/password.png" class="img-fluid ">
                  </div>
                  <input type="submit" :value="$t('login.login')" class="btn btn-white mt-3">
                </form>
                <!-- Authorization Form end-->

                <router-link to="/forgot-password" class="f-1">{{ $t('login.forgot-password') }}</router-link>
              </div>
            </div>

            <language-switcher class="login"></language-switcher>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>


import LanguageSwitcher from '../components/language-switcher';

export default {
  name: 'Login',
  components: { LanguageSwitcher },
  data() {
    return {
      form: {
        code: '',
        password: '',
      },
    };
  },

  methods: {
    login() {
      this.$store.dispatch('auth/login', this.form)
        .then((response) => {
          const user = response.data.data.user

          if (parseInt(user.program_categoryID) === 1) {
            this.$store.dispatch('language/setLocale', 'ka');
          } else {
            this.$store.dispatch('language/setLocale', 'en');
          }

          window.location.href = '/';
        })
        .catch(err => {
          this.helpers.notifyErrorMessage(err);
        });
    },
  },
};
</script>

<style lang="scss">
  .language.login {
    position: absolute;
    top: 40px;
    right: 20px;
  }

  .language.login .nav-link {
    color: #fff;
    font-size: 16px;
    margin-right: 14px;
  }

  @media (min-height: 756px) {
    .language.login {
      top: 100px;
    }
  }

  @media (min-width: 800px) {
    .language.login {
      right: 0;
      bottom: 0;
      align-items: flex-end !important;
    }
  }

  @media (max-width: 800px) and (min-height: 1024px) {
    .language.login {
      right: 0;
      bottom: 0;
      align-items: flex-end !important;
    }
  }
</style>
