import axios from 'axios';

export default {
  namespaced: true,

  state: {
    currentSemester: {}
  },

  getters: {
    currentSemester: state => state.currentSemester,
  },

  mutations: {
    loadCurrentSemester(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.currentSemester = data;
    },
  },

  actions: {
    loadCurrentSemester({ commit }) {
      return new Promise((resolve) => {
        axios.get('/api/current-semester')
          .then((resp) => {
            commit('loadCurrentSemester', resp.data.data.current_semester);
            resolve(resp);
          });
      });
    },

    loadAcademicCalendars() {
      return axios.get('/api/academic-calendars');
    },
  },
};
