<template>
  <b-modal
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    :hide-header-close="true"
    size="lg"
    content-class="modal-blue"
    v-model="isOpen"
    id="upload-vaccine-docs-modal">
    <div class="row">
      <div class="col mb-5 pb-5">
        <h4 class="mb-5 modal-title">{{ $t('vaccine-survey.title') }}</h4>
        <div class="my-4 font-16 text-left">
          <div class="text-danger" v-if="mustUploadVaccineMessage">
            {{ $t('vaccine-survey.attention') }}: {{ mustUploadVaccineMessage }}
          </div>
          <div>
            {{ $t('vaccine-survey.description') }}
          </div>
          <div class="mt-4" v-for="answer of answers">
            <b-form-group>
              <b-form-radio name="vaccinated-status"
                            v-model="vaccinatedAnswer"
                            :value="answer.answerID">
                <div v-html="helpers.getLocalizedField(answer, 'text')"></div>
              </b-form-radio>
            </b-form-group>
            <b-form-group v-if="vaccinatedAnswer === answer.answerID && vaccinatedAnswer != 4" label="">
              <b-form-file
                class="col-12 col-md-6"
                v-model="doc"
                :placeholder="$t('vaccine-survey.upload-file')"
                :drop-placeholder="$t('vaccine-survey.drag-file')"
              ></b-form-file>
            </b-form-group>
          </div>
       </div>
        <div class="my-4 font-16">{{ $t('vaccine-survey.goodbye') }}</div>

      </div>
    </div>
    <div class="row">
      <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
          <button type="button" class="btn btn-light-bold" @click="onSubmitClick">{{ $t('vaccine-survey.submit') }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
export default {
  name: 'upload-vaccine-docs-modal',

  data() {
    return {
      isOpen: true,
      answers: [],
      doc: '',
      vaccinatedAnswer: ''
    }
  },

  computed: {
    ...mapGetters({
      mustUploadVaccineMessage: 'auth/mustUploadVaccineMessage'
    }),
  },

  mounted() {
    this.loadAnswers();
  },

  methods: {
    loadAnswers() {
      axios.get('/api/upload-vaccine-docs')
      .then(response => {
        this.answers = response.data.data;
      })
    },

    showModal() {
      this.$bvModal.show('upload-docs-modal');
    },

    onSubmitClick() {
      const formData = new FormData();

      formData.append('answer_id', this.vaccinatedAnswer);
      formData.append('doc', this.doc);

      axios.post('/api/upload-vaccine-docs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        window.location.reload();
        this.doc = '';
        this.vaccinatedAnswer = '';
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error)
      })
    }
  }
};
</script>

<style>
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  .font-16 {
    font-size: 16px !important;
  }

</style>
