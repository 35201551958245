<template>

  
  
  <div v-if="question.type === 'block'" class="question-block mt-3">
 
    <h4 class="block-title px-2 my-4">{{ question.item.survey_blok_name }}</h4>

    
    
 
    <div class="question" v-for="questionItem of question.item.questions">
 


<!--      <true-false-question :question="questionItem" v-if="questionItem.type === 'კი / არა'"></true-false-question>-->
    

      <closed-question
        v-if="questionItem.type === 'დახურული კითხვა' || questionItem.type === 'კი/არა - მაშინ'"
        @answered="answerClosedQuestion"
        :answer-data="getAnswerData(questionItem.survey_questionID)"
        @getAdditionalQuestionAnswer="getAdditionalQuestionAnswer"
        :question="questionItem"></closed-question>

        <open-question
        v-if="questionItem.type === 'ღია კითხვა'"
        @answered="answerOpenQuestion"
        :answer-data="getAnswerData(questionItem.survey_questionID)"
        :question="questionItem"></open-question>



        <!-- <closed-question-detailed
          v-if="questionItem.type === 'კი/არა - მაშინ'"
          @answered="answerClosedQuestionDetailed"
          :answer-data="getAnswerData(questionItem.survey_questionID)"
          :question="questionItem"></closed-question-detailed> -->

<!--      <rating-question :question="questionItem" v-if="questionItem.type === 'შეაფასება  1-დან(ცუდი)  5-მდე(საუკეთესო)'"></rating-question>-->
    
 
</div>
  </div>


 
  <div v-else class="question">
 
 
    
<!--    <true-false-question :question="question.item" v-if="question.item.type === 'კი / არა'"></true-false-question>-->
  

    <closed-question
      v-if="question.item.type === 'დახურული კითხვა' || question.item.type === 'კი/არა - მაშინ'"
      @answered="answerClosedQuestion"
      :answer-data="getAnswerData(question.item.survey_questionID)"
      @getAdditionalQuestionAnswer="getAdditionalQuestionAnswer"
      :question="question.item"></closed-question>
 
      <open-question
      v-if="question.item.type === 'ღია კითხვა'"
      @answered="answerOpenQuestion"
      :answer-data="getAnswerData(question.item.survey_questionID)"
      :question="question.item"></open-question>

    

      
<!-- 
      <closed-question-detailed
          v-if="question.item.type === 'კი/არა - მაშინ'"
          @answered="answerClosedQuestionDetailed"
          :answer-data="getAnswerData(question.item.survey_questionID)"
          :question="question.item"></closed-question-detailed> -->

   <!-- <rating-question :question="question.item" v-if="question.item.type === 'შეაფასება  1-დან(ცუდი)  5-მდე(საუკეთესო)'"></rating-question> -->
   
  </div>
  
</template>

<script>
import RatingQuestion from './rating-question';
import ClosedQuestion from './closed-question';
 
import OpenQuestion from './open-question';
import TrueFalseQuestion from './true-false-question';

export default {
  name: 'survey-questions',

  components: { RatingQuestion, ClosedQuestion, OpenQuestion, TrueFalseQuestion },

  props: ['question', 'answers', 'subjectId', 'lecturerId'],

  data() {
    return {

    


    }
  },

  mounted() {
  },

 

  

  methods: {
    getAdditionalQuestionAnswer(q,a){
       
      this.$emit('getAdditionalQuestionAnswer',q,a)
     
      
      
    },


    getAnswerData(questionId) {
      return this.answers.find(o => o.questionId == questionId
        && o.lecturerId == this.lecturerId
        && o.subjectId == this.subjectId);
    },

    answerClosedQuestion(questionId, answerId) {

       


      this.$emit('answered', {
        questionId,
        answerId,
        lecturerId: this.lecturerId,
        subjectId: this.subjectId,
        type: 'დახურული კითხვა',
       
      })
    },

  



    answerOpenQuestion(questionId, answer) {
      this.$emit('answered', {
        questionId,
        answer,
        lecturerId: this.lecturerId,
        subjectId: this.subjectId,
        type: 'ღია კითხვა',
        
      })
    }
  }
};
</script>

<style scoped>
  .question-block {
    /* padding: 0 20px; */
    margin-bottom: 2rem;
    border: 1px solid rgba(85, 124, 132, 0.3) !important;
    border-radius: 8px;
    text-align: left;
    background-color: rgba(85, 124, 132, 0.09);
  }
</style>
